import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { updateBilling } from "../../actions/scanFlow";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import LoadingOverlay from "react-loading-overlay";
import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import DatePicker from "react-datepicker";
import { AsyncPaginate } from "react-select-async-paginate";
import { patientService } from "../../services/patients";
import { assistantService } from "../../services/assistants";
import { facilityService } from "../../services/facilities";
import { practiceService } from "../../services/practices";
import { scanFlowService } from "../../services/scanFlow";
import { userService } from "../../services/users";
import { formatDate } from "../../utils/date";
import { createNotification } from "../../utils/notificationManager";
import { insuranceService } from "../../services/insurance";
import loaderImage from "../../../assets/images/CoureMax.gif";
import axios from "axios";

class ManageRevenueScheduler extends React.Component {
  static propTypes = {
    checkedBillings: PropTypes.array.isRequired,
    updateBilling: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      faceSheetFile: null,
      faceSheetFileName: "",
      serviceDate: "",
      reportList: "",
      correspondence: "",
      patient: {},
      assistant: {},
      facility: {},
      assignSorting: {},
      billStatus: "",
      insurance: {},
      practice: {},
      patientChartNo: "",
      assignReg: {},
      regStatus: "",
      assignCoder: {},
      codingStatus: "",
      assignCharge: {},
      chargeStatus: "",
      assignClaim: {},
      claimStatus: "",
      assistantLocation: "",
      hopsContract: "",
      codes: "",
      notes: "",
    };
    this.cancel = "";
  }

  componentDidMount() {
    const checkedBillings = this.props.location.state
      ? this.props.location.state.checkedBillings
      : "";
    if (checkedBillings.length === 1) {
      this.setLoading(true);
      scanFlowService.getBillingDetail(checkedBillings[0]).then((response) => {
        const faceSheetFileArray = response.data.facesheets[0]
          ? response.data.facesheets[0].facesheet_file.split("/")
          : "";
        this.setState(
          {
            patient: {
              label: response.data.patient
                ? `${response.data.patient.first_name.toUpperCase()} ${response.data.patient.last_name.toUpperCase()}`
                : "",
              value: response.data.patient
                ? parseInt(response.data.patient.id)
                : "",
            },
            facility: {
              label: response.data.facility.facility_name.toUpperCase(),
              value: parseInt(response.data.facility.id),
            },
            assistant: {
              label: `${response.data.assistant.name.toUpperCase()} ${response.data.assistant.last_name.toUpperCase()}`,
              value: parseInt(response.data.assistant.id),
            },
            serviceDate: Date.parse(response.data.event_start_date),
            faceSheetFileName:
              faceSheetFileArray[faceSheetFileArray.length - 1],
            billStatus: response.data.bill_status,
            assignReg: {
              label: `${
                response.data.assign_reg
                  ? response.data.assign_reg.name.toUpperCase()
                  : ""
              } ${
                response.data.assign_reg
                  ? response.data.assign_reg.last_name.toUpperCase()
                  : ""
              }`,
              value: parseInt(
                response.data.assign_reg ? response.data.assign_reg.id : ""
              ),
            },
            regStatus: response.data.reg_status ? response.data.reg_status : "",
            assignCoder: {
              label: `${
                response.data.assign_coder
                  ? response.data.assign_coder.name.toUpperCase()
                  : ""
              } ${
                response.data.assign_coder
                  ? response.data.assign_coder.last_name.toUpperCase()
                  : ""
              }`,
              value: parseInt(
                response.data.assign_coder ? response.data.assign_coder.id : ""
              ),
            },
            codingStatus: response.data.coding_status
              ? response.data.coding_status
              : "",
            assignCharge: {
              label: `${
                response.data.assign_charge
                  ? response.data.assign_charge.name.toUpperCase()
                  : ""
              } ${
                response.data.assign_charge
                  ? response.data.assign_charge.last_name.toUpperCase()
                  : ""
              }`,
              value: parseInt(
                response.data.assign_charge
                  ? response.data.assign_charge.id
                  : ""
              ),
            },
            chargeStatus: response.data.charge_status
              ? response.data.charge_status
              : "",
            assignClaim: {
              label: `${
                response.data.assign_claim
                  ? response.data.assign_claim.name.toUpperCase()
                  : ""
              } ${
                response.data.assign_claim
                  ? response.data.assign_claim.last_name.toUpperCase()
                  : ""
              }`,
              value: parseInt(
                response.data.assign_claim ? response.data.assign_claim.id : ""
              ),
            },
            claimStatus: response.data.claim_status
              ? response.data.claim_status
              : "",
            hopsContract: response.data.hosp_contract
              ? response.data.hosp_contract.toUpperCase()
              : "",
            assignSorting: {
              label: `${
                response.data.practice_assignor
                  ? response.data.practice_assignor.name.toUpperCase()
                  : ""
              } ${
                response.data.practice_assignor
                  ? response.data.practice_assignor.last_name.toUpperCase()
                  : ""
              }`,
              value: parseInt(
                response.data.practice_assignor
                  ? response.data.practice_assignor.id
                  : ""
              ),
            },
            assistantLocation:
              response.data.assistant && response.data.assistant.region
                ? response.data.assistant.region.name
                : "",
            insurance: {
              label:
                response.data.patient &&
                response.data.patient.patient_insurance_data &&
                response.data.patient.patient_insurance_data.primary
                  ? response.data.patient.patient_insurance_data.primary.description.toUpperCase()
                  : "",
              value:
                response.data.patient &&
                response.data.patient.patient_insurance_data &&
                response.data.patient.patient_insurance_data.primary
                  ? response.data.patient.patient_insurance_data.primary.id
                  : "",
            },
            patientChartNo: response.data.patient.id,
            practice: {
              label: response.data.practice
                ? response.data.practice.paytoname.toUpperCase()
                : "",
              value: parseInt(
                response.data.practice ? response.data.practice.id : ""
              ),
            },
            codes: response.data.cpt_codes,
            notes: response.data.notes,
          },
          () => {
            this.setLoading(false);
          }
        );
      });
    }
  }

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  cancelNav = () => {
    this.props.history.push({
      pathname: APP_ROUTES.SCAN_FLOW_DASHBOARD,
      state: {
        currentPage: this.props.location.state.currentPage,
        pageSize: this.props.location.state.pageSize,
      },
    });
  };

  update = () => {
    const { updateBilling } = this.props;
    const {
      patient,
      facility,
      assistant,
      serviceDate,
      assignSorting,
      billStatus,
      insurance,
      practice,
      patientChartNo,
      assignReg,
      regStatus,
      assignCoder,
      codingStatus,
      assignCharge,
      chargeStatus,
      assignClaim,
      claimStatus,
      assistantLocation,
      hopsContract,
      codes,
      notes,
    } = this.state;

    const checkedBillings = this.props.location.state
      ? this.props.location.state.checkedBillings
      : "";
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    this.setLoading(true);
    if (checkedBillings.length === 1) {
      const request = {
        event_id: checkedBillings,
        patient_id: patient.value,
        facility: facility.value,
        assistant_id: assistant.value,
        dos: formatDate(serviceDate),
        practice: practice.value,
        assign_reg_id: assignReg.value,
        reg_status: regStatus,
        assign_code_id: assignCoder.value,
        coding_status: codingStatus,
        assign_charge_id: assignCharge.value,
        charge_status: chargeStatus,
        assign_claim_id: assignClaim.value,
        claim_status: claimStatus,
        hosp_contract: hopsContract,
        assign_sorting_id: assignSorting.value,
        bill_status: billStatus,
        insurance: insurance.value,
        codes,
        notes,
        modified_by: loggedInUser.id,
      };

      updateBilling(request)
        .then((res) => {
          if (res.response.data.success) {
            this.props.history.push(APP_ROUTES.SCAN_FLOW_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push({
              pathname: APP_ROUTES.SCAN_FLOW_DASHBOARD,
              state: {
                currentPage: this.props.location.state.currentPage,
                pageSize: this.props.location.state.pageSize,
              },
            });
          }
          this.setLoading(false);
        })
        .catch((err) => {
          this.setLoading(false);
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to update activity ${err.error.response.data.message}`
          );
        });
    } else {
      let request = {
        event_id: checkedBillings,
      };

      if (serviceDate) {
        request = { ...request, dos: serviceDate };
      }
      if (patient.value) {
        request = { ...request, patient_id: patient.value };
      }
      if (assistant.value) {
        request = { ...request, assistant_id: assistant.value };
      }
      if (facility.value) {
        request = { ...request, facility: facility.value };
      }
      if (assignSorting.value) {
        request = { ...request, assign_sorting_id: assignSorting.value };
      }
      if (billStatus) {
        request = { ...request, bill_status: billStatus };
      }
      if (insurance.value) {
        request = { ...request, insurance: insurance.value };
      }
      if (practice.value) {
        request = { ...request, practice: practice.value };
      }
      if (assignReg.value) {
        request = { ...request, assign_reg_id: assignReg.value };
      }
      if (regStatus) {
        request = { ...request, reg_status: regStatus };
      }
      if (assignCoder.value) {
        request = { ...request, assign_code_id: assignCoder.value };
      }
      if (codingStatus) {
        request = { ...request, coding_status: codingStatus };
      }
      if (assignCharge.value) {
        request = { ...request, assign_charge_id: assignCharge.value };
      }
      if (chargeStatus) {
        request = { ...request, charge_status: chargeStatus };
      }
      if (assignClaim.value) {
        request = { ...request, assign_claim_id: assignClaim.value };
      }
      if (claimStatus) {
        request = { ...request, claim_status: claimStatus };
      }
      if (codes) {
        request = { ...request, codes: codes };
      }
      if (notes) {
        request = { ...request, notes: notes };
      }
      request = { ...request, modified_by: loggedInUser.id };

      updateBilling(request)
        .then((res) => {
          if (res.response.data.success) {
            this.props.history.push(APP_ROUTES.SCAN_FLOW_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push({
              pathname: APP_ROUTES.SCAN_FLOW_DASHBOARD,
              state: {
                currentPage: this.props.location.state.currentPage,
                pageSize: this.props.location.state.pageSize,
              },
            });
          }
          this.setLoading(false);
        })
        .catch((err) => {
          this.setLoading(false);
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to update activity ${err.message}`
          );
        });
    }
  };

  onChangePatient = (value) => {
    this.setState({ patient: value });
  };

  loadPatient = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return patientService
      .getPatients({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((patient) => ({
          value: patient.id,
          label: `${patient.first_name} ${patient.last_name}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadAssistant = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return assistantService
      .getAssistants({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((assistant) => ({
          value: assistant.assistant_id,
          label: `${assistant.NAME} ${assistant.last_name}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  onChangeAssistant = (value) => {
    this.setState({ assistant: value });
  };

  loadFacility = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return facilityService
      .getFacilities({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((facility) => ({
          value: facility.id,
          label: facility.facility_name,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadInsurance = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    options = { ...options, api_type: "react" };

    return insuranceService
      .getInsurances({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((insurance) => ({
          value: insurance.id,
          label: `${insurance.description} (${insurance.address1})`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadPractices = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return practiceService
      .getPractices({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((practice) => ({
          value: practice.id,
          label: practice.paytoname,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadUsers = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return userService
      .getUsers({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((user) => ({
          value: user.id,
          label: `${user.name} ${user.last_name}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  onChangeFacility = (value) => {
    this.setState({ facility: value });
  };

  render() {
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={this.props.activePage} />
        </div>
        <TopNavbar />
        <LoadingOverlay
          active={this.state.loading}
          spinner={
            <img
              style={{
                width: "200px",
              }}
              src={loaderImage}
              alt="loading..."
            />
          }
        >
          <div className="manage-activity-right-col">
            <div className="activity-row">
              <div className="page-title">
                <h2 className="heading-custom">Edit</h2>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Service Date</label>
                <br />
                <DatePicker
                  isClearable
                  selected={this.state.serviceDate}
                  onChange={(date) => this.setState({ serviceDate: date })}
                  className="date-picker"
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Patient</label>
                <AsyncPaginate
                  placeholder="Select Patient..."
                  value={this.state.patient}
                  loadOptions={this.loadPatient}
                  onChange={this.onChangePatient}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Assistant</label>
                <AsyncPaginate
                  placeholder="Select Assistant..."
                  value={this.state.assistant}
                  loadOptions={this.loadAssistant}
                  onChange={this.onChangeAssistant}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Facility</label>
                <AsyncPaginate
                  placeholder="Select Facility..."
                  value={this.state.facility}
                  loadOptions={this.loadFacility}
                  onChange={this.onChangeFacility}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Assign Sorting</label>
                <AsyncPaginate
                  placeholder="-- Assign Sorting --"
                  value={this.state.assignSorting}
                  loadOptions={this.loadUsers}
                  onChange={(value) => this.setState({ assignSorting: value })}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Bill Status</label>
                <select
                  className="filter-input"
                  value={this.state.billStatus}
                  onChange={(event) =>
                    this.setState({ billStatus: event.target.value })
                  }
                >
                  <option value="">Bill Status</option>
                  <option value="billable">Billable</option>
                  <option value="non billable">Non Billable</option>
                  <option value="hospital contract">Hospital Contract</option>
                  <option value="hospital contract second scrub">
                    Hospital Contract Second Scrub
                  </option>
                  <option value="bill to patient">Bill to Patient</option>
                  <option value="bill to physician">Bill to Physician</option>
                </select>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Insurance</label>
                <AsyncPaginate
                  placeholder="-- Select Insurance --"
                  value={this.state.insurance}
                  loadOptions={this.loadInsurance}
                  onChange={(value) => this.setState({ insurance: value })}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Practice</label>
                <AsyncPaginate
                  placeholder="-- Select Practice --"
                  value={this.state.practice}
                  loadOptions={this.loadPractices}
                  onChange={(value) => this.setState({ practice: value })}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Patient Chart No</label>
                <input
                  type="text"
                  className="code-input"
                  value={this.state.patientChartNo}
                  onChange={(event) =>
                    this.setState({ patientChartNo: event.target.value })
                  }
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Assign Reg</label>
                <AsyncPaginate
                  placeholder="-- Assign Reg --"
                  value={this.state.assignReg}
                  loadOptions={this.loadUsers}
                  onChange={(value) => this.setState({ assignReg: value })}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Reg Status</label>
                <select
                  className="filter-input"
                  value={this.state.regStatus}
                  onChange={(event) =>
                    this.setState({ regStatus: event.target.value })
                  }
                >
                  <option value="">Reg Status</option>
                  <option value="not started">NOT STARTED</option>
                  <option value="completed">COMPLETED</option>
                </select>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Assign Coder</label>
                <AsyncPaginate
                  placeholder="-- Assign Coder --"
                  value={this.state.assignCoder}
                  loadOptions={this.loadUsers}
                  onChange={(value) => this.setState({ assignCoder: value })}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Coding Status</label>
                <select
                  value={this.state.codingStatus}
                  className="filter-input"
                  onChange={(event) =>
                    this.setState({ codingStatus: event.target.value })
                  }
                >
                  <option value="">Coding Status</option>
                  <option value="not started">NOT STARTED</option>
                  <option value="3m">3M</option>
                  <option value="ready to bill">READY TO BILL</option>
                  <option value="op request">OP Request</option>
                  <option value="in house coding">IN HOUSE CODING</option>
                </select>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Assign Charge</label>
                <AsyncPaginate
                  placeholder="-- Assign Charge --"
                  value={this.state.assignCharge}
                  loadOptions={this.loadUsers}
                  onChange={(value) => this.setState({ assignCharge: value })}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Charge Status</label>
                <select
                  value={this.state.chargeStatus}
                  className="filter-input"
                  onChange={(event) =>
                    this.setState({ chargeStatus: event.target.value })
                  }
                >
                  <option value="">Charge Status</option>
                  <option value="not started">NOT STARTED</option>
                  <option value="ready to submit">READY TO SUBMIT</option>
                </select>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Assign Claim</label>
                <AsyncPaginate
                  placeholder="-- Assign Claim --"
                  value={this.state.assignClaim}
                  loadOptions={this.loadUsers}
                  onChange={(value) => this.setState({ assignClaim: value })}
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Claim Status</label>
                <select
                  value={this.state.claimStatus}
                  className="filter-input"
                  onChange={(event) =>
                    this.setState({ claimStatus: event.target.value })
                  }
                >
                  <option value="">Claim Status</option>
                  <option value="not started">NOT STARTED</option>
                  <option value="submitted">SUBMITTED</option>
                  <option value="corrected claim">CORRECTED CLAIM</option>
                  <option value="paper claim">PAPER CLAIM</option>
                  <option value="reconsideration">RECONSIDERATION</option>
                  <option value="followup">FOLLOW-UP</option>
                  <option value="appeal 1">APPEAL 1</option>
                  <option value="appeal 2">APPEAL 2</option>
                  <option value="hold to bill">HOLD TO BILL</option>
                  <option value="bill patient">BILL PATIENT</option>
                  <option value="bill hospital">BILL HOSPITAL</option>
                  <option value="bill physician">BILL PHYSICIAN</option>
                  <option value="review adjustment">REVIEW ADJUSTMENT</option>
                  <option value="closed">CLOSED</option>
                  <option value="closed non-bill">CLOSED NON-BILL</option>
                  <option value="closed adjustment">CLOSED ADJUSTMENT</option>
                  <option value="closed hc-2nd scrub">
                    CLOSED HC-2nd Scrub
                  </option>
                </select>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Assitant Location</label>
                <input
                  type="text"
                  disabled
                  className="code-input"
                  value={this.state.assistantLocation}
                  onChange={(event) =>
                    this.setState({ assistantLocation: event.target.value })
                  }
                />
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Hosp Contract</label>
                <select
                  name="hosp_contract"
                  id="hosp_contract2"
                  value={this.state.hopsContract}
                  className="filter-input"
                  onChange={(event) =>
                    this.setState({ hopsContract: event.target.value })
                  }
                >
                  <option value="">Hosp Contract</option>
                  <option value="billed hospital">Billed Hospital</option>
                  <option value="payment received">Payment Recived</option>
                </select>
              </div>
              <div className="col-md-3 mrgn-btm10">
                <label>Codes</label>
                <input
                  type="text"
                  className="code-input"
                  value={this.state.codes}
                  onChange={(event) =>
                    this.setState({ codes: event.target.value })
                  }
                />
              </div>
              <div className="col-md-12 mrgn-btm10">
                <label>Notes</label>
                <textarea
                  className="code-input"
                  value={this.state.notes}
                  onChange={(event) =>
                    this.setState({ notes: event.target.value })
                  }
                />
              </div>
              <div className="filter-footer">
                <button onClick={this.update} className="filter-save-button">
                  Update
                </button>
                <button
                  onClick={this.cancelNav}
                  className="filter-cancel-button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateBilling,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageRevenueScheduler));
