import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchUsers } from "../../actions/users";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import Loader from "../atom/Loader";
import { USER_LISTING_COLUMNS, APP_ROUTES } from "../../constants/common";
import UserListingTable from "../atom/UserListingTable";
import { userService } from "../../services/users";

class UserDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    searchByName: "",
    searched: false,
    searchedUsers: [],
    searchedTotalRecords: 0,
  };

  componentWillMount() {
    this.setState({ loading: true });
    const options = {
      page: this.state.currentPage,
    };
    this.props.fetchUsers({ params: options }).then((res) => {
      this.setState({ loading: false });
    });
  }

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  goToPage = (page) => {
    this.setLoading(true);
    this.setCurrentPage(page);

    const options = {
      page: page,
    };
    this.props.fetchUsers({ params: options }).then((res) => {
      this.setLoading(false);
    });
  };

  showAdd = () => this.props.history.push(APP_ROUTES.ADD_USER);

  showManageUser = (userId) =>
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_USER,
      state: {
        userId,
        activePage:
          this.props.activePage || this.props.location.state.activePage,
      },
    });

  deleteUser = (user) => {
    userService.deleteUser(user).then((response) => {
      this.goToPage(1);
    });
  };

  list = () => {
    const { users = [] } = this.props;
    return users.map((user) => {
      return [
        user.id,
        `${user.name ? user.name : ""} ${
          user.last_name ? user.last_name : ""
        }`.toUpperCase(),
        user.email,
        user.type ? user.type.toUpperCase() : "",
        user.id,
      ];
    });
  };

  searchUsers = () => {
    const { searchByName } = this.state;
    let options = {};

    if (searchByName) {
      options = { ...options, search: searchByName };
    }

    this.setState({
      searched: true,
      loading: true,
    });
    userService.getUsers({ params: options }).then((response) => {
      this.setState({
        searchedTotalRecords: parseInt(response.records),
        searchedUsers: response.data.map((user) => {
          return [
            user.id,
            `${user.name ? user.name : ""} ${
              user.last_name ? user.last_name : ""
            }`.toUpperCase(),
            user.email,
            user.type ? user.type.toUpperCase() : "",
            user.id,
          ];
        }),
      });
      this.setState({ loading: false });
    });
  };

  resetUserSearch = () => {
    this.setState({
      searchByName: "",
      searchedUsers: [],
      searched: false,
      searchedTotalRecords: 0,
    });
  };

  render() {
    const activePage =
      this.props.activePage || this.props.location.state.activePage;
    const { searched, searchedUsers, searchedTotalRecords } = this.state;
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={activePage} />
        </div>
        <TopNavbar />
        <div
          style={{
            minHeight: "755px",
          }}
          className="right-col"
        >
          <div className="page-title">
            <div className="title_left">
              <h3
                style={{
                  fontFamily:
                    "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "1.471",
                }}
              >
                Users
              </h3>
            </div>
          </div>
          <div className="filter-footer-header">
            <button
              onClick={() => {
                this.showAdd(true);
              }}
              className="filter-button"
            >
              Add New User
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                paddingLeft: "10px",
              }}
              className="col-md-3 mrgn-btm10"
            >
              <label>User Name</label>
              <input
                type="text"
                className="code-input"
                value={this.state.searchByName}
                onChange={(event) => {
                  this.setState({ searchByName: event.target.value }, () => {
                    if (this.state.searchByName.length >= 3) {
                      this.searchUsers();
                    } else if (this.state.searchByName.length === 0) {
                      this.resetUserSearch();
                    }
                  });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <button
                style={{
                  marginTop: "32px",
                  width: "100px",
                  height: "38px",
                }}
                onClick={this.resetUserSearch}
                className="filter-save-button"
              >
                Reset
              </button>
            </div>
          </div>
          <div className="row">
            <div className="activities-header">
              <UserListingTable
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                edit={this.showManageUser}
                delete={this.deleteUser}
                headColumn={USER_LISTING_COLUMNS}
                listing={
                  searched && searchedUsers.length > 0
                    ? searchedUsers
                    : this.list()
                }
                totalRecords={
                  searched && searchedTotalRecords > 0
                    ? searchedTotalRecords
                    : this.props.totalRecords
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.userReducer.users,
    totalRecords: state.userReducer.totalUsers,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUsers,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDashboard));
