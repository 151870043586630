import React from 'react';
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import EventCalendar from "../molecules/EventCalendar";
import {bindActionCreators} from "redux";
import {fetchActivitiesForScheduling} from "../../actions/dashboard";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import PropTypes from "prop-types";
import {formatDate} from "../../utils/date";
import DatePicker from "react-datepicker";
import {AsyncPaginate} from "react-select-async-paginate";
import {RECORDS_PER_PAGE} from "../../constants/common";
import {regionsService} from "../../services/regions";
import {assistantService} from "../../services/assistants";
import {eventTypesService} from "../../services/eventTypes";

import '../../../sass/scheduling.scss';
import loaderImage from "../../../assets/images/CoureMax.gif";
import axios from "axios";

class Dashboard extends React.Component {
    static propTypes = {
        totalRecords: PropTypes.number.isRequired,
        activities: PropTypes.array.isRequired,
        fetchActivitiesForScheduling: PropTypes.func.isRequired,
    };

    constructor( props ) {
        super( props );

        this.state = {
            events: [],
            loading: false,
            currentPage: 1,
            fromDate: '',
            toDate: '',
            region: {
                value: '',
                label: 'Select Region',
            },
            assistant: {
                value: '',
                label: 'Select Assistant',
            },
            type: {
                value: '',
                label: 'Select Type',
            },
            eventType: 'month',
        };
        this.cancel = '';
    }

    componentDidMount() {
        this.loadEvents();
    }

    loadEvents = () => {
        this.setState({loading: true});
        const options = {
            type: this.state.eventType,
            calendar: true,
        };
        this.props.fetchActivitiesForScheduling({ params: options })
            .then(res => {
                this.setState({
                    events: res.response.data.map(event => {
                        return {
                            start: new Date(formatDate(event.event_start_date)),
                            end: new Date(formatDate(event.event_end_date)),
                            title: event.assistant ? `${event.assistant.name} ${event.assistant.last_name}` : '',
                            id: event.old_event_id,
                            status: event.status,
                        }
                    })
                });
                this.setState({loading: false});
            })
            .catch(error => {
                this.setState({loading: false});
            });
    };

    addFilters = (options) => {
        const {
            fromDate,
            toDate,
            region,
            assistant,
            type,
        } = this.state;
        if (fromDate) {
            options = {...options, from_date: formatDate(fromDate)};
        }
        if (toDate) {
            options = {...options, to_date: formatDate(toDate)};
        }
        if (region.value) {
            options = {...options, company_id: region.value};
        }
        if (assistant.value) {
            options = {...options, assistant_id: assistant.value};
        }
        if (type.value) {
            options = {...options, type: type.value};
        }
        return options;
    };

    search = () => {
        this.setState({ loading: true });
        const options = {
            type: this.state.eventType,
            calendar: true,
        };
        this.props.fetchActivitiesForScheduling({ params: this.addFilters(options) })
            .then(res => {
                this.setState({
                    events: res.response.data.map(event => {
                        return {
                            start: new Date(formatDate(event.event_start_date)),
                            end: new Date(formatDate(event.event_end_date)),
                            title: event.assistant ? `${event.assistant.name} ${event.assistant.last_name}` : '',
                            id: event.old_event_id,
                            status: event.status,
                        }
                    })
                });
                this.setState({ loading: false });
            });
    };

    setFromDate = (date) => {
        this.setState({ fromDate: date });
    };

    setToDate = (date) => {
        this.setState({ toDate: date });
    };

    setRegion = (value) => {
        this.setState({ region: value });
    };

    setAssistant = (value) => {
        this.setState({ assistant: value });
    };

    setType = (value) => {
        this.setState({ type: value });
    };

    resetFilter = () => {
        this.setFromDate('');
        this.setToDate('');
        this.setRegion({
            value: '',
            label: 'Select Region',
        });
        this.setAssistant({
            value: '',
            label: 'Select Assistant',
        });
        this.setType({
            value: '',
            label: 'Select Type',
        });
        this.loadEvents();
    };

    loadRegion = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        if(this.cancel) {
            this.cancel.cancel();
        }

        this.cancel = axios.CancelToken.source();

        return regionsService
            .getRegions({ params: options }, this.cancel.token)
            .then(response => {
                const options = response.data.map(region => (
                    {
                        value: region.id,
                        label: region.name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadEventTypes = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        if(this.cancel) {
            this.cancel.cancel();
        }

        this.cancel = axios.CancelToken.source();

        return eventTypesService
            .getEventTypes({ params: options }, this.cancel.token)
            .then(response => {
                const options = response.data.map(eventType => (
                    {
                        value: eventType.id,
                        label: eventType.event_type_name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadAssistant = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        if(this.cancel) {
            this.cancel.cancel();
        }

        this.cancel = axios.CancelToken.source();

        if (this.state.region.value) {
            return assistantService
                .getRegionAssistants(this.state.region.value)
                .then(response => {
                    const options = response.data.map(assistant => (
                        {
                            value: assistant.assistant_id,
                            label: `${assistant.name} ${assistant.last_name}`,
                        }
                    ));

                    return {
                        options: options,
                        hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                    }
                });
        } else {
            return assistantService
                .getAssistants({ params: options }, this.cancel.token)
                .then(response => {
                    const options = response.data.map(assistant => (
                        {
                            value: assistant.assistant_id,
                            label: `${assistant.first_name} ${assistant.last_name}`,
                        }
                    ));

                    return {
                        options: options,
                        hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                    }
                });
        }
    };

    render() {
        const activePage = this.props.activePage || this.props.location.state.activePage;
        return(
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col"
                style={{
                    minHeight: '132vh',
                }}>
                    <Sidebar activePage={activePage} />
                </div>
                <TopNavbar />
                <div className="right-col">
                    <div className="custom-class">
                        <div className="scheduling-filters-column">
                            <label>From Date</label>
                            <DatePicker
                                isClearable
                                popperPlacement='right-start'
                                className="filter-input"
                                selected={this.state.fromDate}
                                onChange={date => this.setFromDate(date)}
                            />
                        </div>
                        <div className="scheduling-filters-column">
                            <label>To Date</label>
                            <DatePicker
                                isClearable
                                className="filter-input"
                                popperPlacement='right-start'
                                selected={this.state.toDate}
                                onChange={date => this.setToDate(date)}
                            />
                        </div>
                        <div className="scheduling-filters-column">
                            <label>Region</label>
                            <AsyncPaginate
                                placeholder="-- Region --"
                                value={this.state.region}
                                loadOptions={this.loadRegion}
                                defaultOptions={[
                                    {
                                        value: '',
                                        label: 'Select Region',
                                    }
                                ]}
                                onChange={value => this.setRegion(value)}
                            />
                        </div>
                        <div className="scheduling-filters-column">
                            <label>Assistant</label>
                            <AsyncPaginate
                                placeholder="-- Assistant --"
                                value={this.state.assistant}
                                loadOptions={this.loadAssistant}
                                defaultOptions={[
                                    {
                                        value: '',
                                        label: 'Select Assistant',
                                    }
                                ]}
                                onChange={value => this.setAssistant(value)}
                            />
                        </div>
                        <div className="scheduling-filters-column">
                            <label>Type</label>
                            <AsyncPaginate
                                placeholder="-- Type --"
                                value={this.state.type}
                                loadOptions={this.loadEventTypes}
                                defaultOptions={[
                                    {
                                        value: '',
                                        label: 'Select Type',
                                    }
                                ]}
                                onChange={value => this.setType(value)}
                            />
                        </div>
                        <div className="filter-footer">
                            <button
                                type="submit"
                                onClick={this.resetFilter}
                                className="scheduling-filter-button">
                                Refresh
                            </button>
                            <button
                                type="submit"
                                onClick={this.search}
                                className="scheduling-filter-button">
                                Search
                            </button>
                        </div>
                    </div>
                    <br/>
                    <div style={{
                        maxWidth: '20%',
                    }}>
                        <label>Event Type</label>
                        <select
                            className="filter-input"
                            value={this.state.eventType}
                            onChange={event => this.setState({ eventType: event.target.value }, () => this.loadEvents())}>
                            <option value="past">Past</option>
                            <option value="future">Future</option>
                            <option value="month">Current Month</option>
                        </select>
                    </div>
                    <div className="bottom-spacing">
                        <LoadingOverlay
                            active={this.state.loading}
                            className='z-index'
                            spinner={<img
                                style={{
                                    width: '200px',
                                }}
                                src={loaderImage}
                                alt="loading..."
                            />}>
                            <EventCalendar
                                events={this.state.events}
                            />
                        </LoadingOverlay>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activities: state.dashboardReducer.activities,
        totalRecords: state.dashboardReducer.totalActivities,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchActivitiesForScheduling,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Dashboard));