import {ASSISTANT} from '../constants/actions';

const initialState = {
    assistants: [],
    totalAssistants: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ASSISTANT.FETCH_ASSISTANT_SUCCESS :
            return {
                ...state,
                assistants: action.data.data,
                totalAssistants: action.data.records,
            };
        case ASSISTANT.CREATE_ASSISTANT_SUCCESS:
            const assistants = [ ...state.assistants, action.data.data ];
            return {
                ...state,
                assistants: assistants,
            }
        case ASSISTANT.UPDATE_ASSISTANT_SUCCESS:
            const updatedAssistants = [ ...state.assistants, action.data.data ];
            return {
                ...state,
                assistants: updatedAssistants,
            }
        case ASSISTANT.DELETE_ASSISTANT_SUCCESS:
            const deletedAssistants = [ ...state.assistants, action.data.data ];
            return {
                ...state,
                assistants: deletedAssistants,
            }
        default:
            return state;
    }
}