import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { APP_ROUTES, NOTIFICATION_TYPES } from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { createPractice, updatePractice } from "../../actions/practice";
import { practiceService } from "../../services/practices";

const REQUIRED_FIELDS = {
  PAYTONPI: "PAYTONPI",
  PAYTONAME: "paytoname",
};

class ManagePractice extends React.Component {
  state = {
    errors: [],
    paytonpi: "",
    paytoname: "",
    organization_individual: "Organization",
    paytofirst_name: "",
    billing_address: "",
    billing_address2: "",
    paytocity: "",
    paytostate: "",
    paytozip: "",
    tax_identification: "",
    ein_ssn: "",
    clia_number: "",
    taxonomy_code: "",
  };

  componentDidMount() {
    const practiceId = this.props.location.state
      ? this.props.location.state.practiceId
      : "";
    if (practiceId) {
      practiceService.getPracticeDetail(practiceId).then((response) => {
        this.setState({
          paytonpi: response.data.paytonpi,
          paytoname: response.data.paytoname,
          organization_individual: response.data.organization_individual,
          paytofirst_name: response.data.paytofirst_name,
          billing_address: response.data.billing_address,
          billing_address2: response.data.billing_address2,
          paytocity: response.data.paytocity,
          paytostate: response.data.paytostate,
          paytozip: response.data.paytozip,
          tax_identification: response.data.tax_identification,
          ein_ssn: response.data.ein_ssn,
          clia_number: response.data.clia_number,
          taxonomy_code: response.data.taxonomy_code,
        });
      });
    }
  }

  hasErrors = (field) => {
    return this.state.errors.includes(field);
  };

  resetErrors = (field) => {
    this.setState({
      errors: this.state.errors.filter((fieldName) => fieldName !== field),
    });
  };

  validatePractice = () => {
    const { paytonpi, paytoname, errors } = this.state;

    let isValid = true;

    if (paytonpi.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.PAYTONPI] });
      isValid = false;
    }
    if (paytoname.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.PAYTONAME] });
      isValid = false;
    }

    if (!isValid) {
      createNotification(
        NOTIFICATION_TYPES.ERROR,
        "Please select required values"
      );
    }

    return isValid;
  };

  submitPractice = () => {
    if (!this.validatePractice()) {
      return;
    }
    const { createPractice, updatePractice } = this.props;
    const {
      paytonpi,
      paytoname,
      organization_individual,
      paytofirst_name,
      billing_address,
      billing_address2,
      paytocity,
      paytostate,
      paytozip,
      tax_identification,
      ein_ssn,
      clia_number,
      taxonomy_code,
    } = this.state;

    const request = {
      paytonpi: paytonpi,
      paytoname: paytoname,
      Organization_Individual: organization_individual,
      paytofirst_name: paytofirst_name,
      billing_address: billing_address,
      billing_address2: billing_address2,
      paytocity: paytocity,
      paytostate: paytostate,
      paytozip: paytozip,
      tax_identification: tax_identification,
      ein_ssn: ein_ssn,
      clia_number: clia_number,
      taxonomy_code: taxonomy_code,
    };

    const practiceId = this.props.location.state
      ? this.props.location.state.practiceId
      : "";
    if (practiceId) {
      const payload = { ...request, id: practiceId };
      updatePractice(payload)
        .then((res) => {
          if (res.response.success) {
            this.props.history.push(APP_ROUTES.PRACTICE_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            //this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
          }
        })
        .catch((err) => {
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to update user ${err.error.response.data.message}`
          );
        });
    } else {
      createPractice(request)
        .then((res) => {
          if (res.response.success) {
            this.props.history.push(APP_ROUTES.PRACTICE_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            //this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
          }
        })
        .catch((err) => {
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to create Practice ${err.error.response.data.message}`
          );
        });
    }
  };

  toggleCheckbox = (name, event) => {
    let obj = {};
    obj[name] = !this.state[name];
    this.setState(obj);
  };

  cancel = () => {
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    if (screen) {
      this.props.history.push(screen);
    } else {
      this.props.history.push(APP_ROUTES.PRACTICE_DASHBOARD);
    }
  };

  render() {
    const practiceId = this.props.location.state
      ? this.props.location.state.practiceId
      : "";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {practiceId && <h2 className="heading-custom">Edit Practice</h2>}
              {!practiceId && <h2 className="heading-custom">Add Practice</h2>}
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>
                Pay To NPI Number<span className="required">*</span>
              </label>
              <input
                type="text"
                className={
                  this.hasErrors(REQUIRED_FIELDS.PAYTONPI)
                    ? "error code-input"
                    : "code-input"
                }
                value={this.state.paytonpi}
                onChange={(event) => {
                  this.resetErrors(REQUIRED_FIELDS.PAYTONPI);
                  this.setState({ paytonpi: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>
                Pay To Name<span className="required">*</span>
              </label>
              <input
                type="text"
                className={
                  this.hasErrors(REQUIRED_FIELDS.PAYTONAME)
                    ? "error code-input"
                    : "code-input"
                }
                value={this.state.paytoname}
                onChange={(event) => {
                  this.resetErrors(REQUIRED_FIELDS.PAYTONAME);
                  this.setState({ paytoname: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>Pay To Organization/Individual </label>
              <select
                className="filter-input"
                value={this.state.organization_individual}
                onChange={(event) =>
                  this.setState({ organization_individual: event.target.value })
                }
              >
                <option value="Organization">Pay To Organization</option>
                <option value="Individual">Pay To Individual</option>
              </select>
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Pay To First Name </label>
              <input
                type="text"
                className="code-input"
                value={this.state.paytofirst_name}
                onChange={(event) => {
                  this.setState({ paytofirst_name: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Billing Address 1</label>
              <input
                type="text"
                className="code-input"
                value={this.state.billing_address}
                onChange={(event) => {
                  this.setState({ billing_address: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Billing Address 2 </label>
              <input
                type="text"
                className="code-input"
                value={this.state.billing_address2}
                onChange={(event) => {
                  this.setState({ billing_address2: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>City </label>
              <input
                type="text"
                className="code-input"
                value={this.state.paytocity}
                onChange={(event) => {
                  this.setState({ paytocity: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>State </label>
              <input
                type="text"
                className="code-input"
                value={this.state.paytostate}
                onChange={(event) => {
                  this.setState({ paytostate: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Zip </label>
              <input
                type="text"
                className="code-input"
                value={this.state.paytozip}
                onChange={(event) => {
                  this.setState({ paytozip: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Tax Identification</label>
              <select
                className="filter-input"
                value={this.state.tax_identification}
                onChange={(event) =>
                  this.setState({ tax_identification: event.target.value })
                }
              >
                <option value="ein">Use EIN</option>
                <option value="ssn">Use SSN</option>
              </select>
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>EIN or SSN (ONLY Numbers, NO dashes or spaces) </label>
              <input
                type="text"
                className="code-input"
                value={this.state.ein_ssn}
                onChange={(event) => {
                  this.setState({ ein_ssn: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>CLIA Number (Optional - Used For Labs)</label>
              <input
                type="text"
                className="code-input"
                value={this.state.clia_number}
                onChange={(event) => {
                  this.setState({ clia_number: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Taxonomy Code</label>
              <input
                type="text"
                className="code-input"
                value={this.state.taxonomy_code}
                onChange={(event) => {
                  this.setState({ taxonomy_code: event.target.value });
                }}
              />
            </div>
          </div>
          <div className="filter-footer">
            {practiceId && (
              <button
                onClick={this.submitPractice}
                className="filter-save-button"
              >
                Update
              </button>
            )}
            {!practiceId && (
              <button
                onClick={this.submitPractice}
                className="filter-save-button"
              >
                Submit
              </button>
            )}
            <button onClick={this.cancel} className="filter-cancel-button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPractice,
      updatePractice,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManagePractice));
