import { AUTH } from '../constants/actions';

const initialState = {
    authInfo: {},
};

export default function(state = initialState, action) {
    switch (action.type) {
        case AUTH.OFFICE_365_AUTHENTICATION:
            return { ...state, authInfo: action.data };
        case AUTH.REGISTRATION_SUCCESS:
            return {...state}
        default:
            return state;
    }
}