import React from "react";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {bindActionCreators} from "redux";
import {createBatch, updateBatch} from "../../actions/batches";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {APP_ROUTES, NOTIFICATION_TYPES, RECORDS_PER_PAGE} from "../../constants/common";
import DatePicker from "react-datepicker";
import {AsyncPaginate} from "react-select-async-paginate";
import {insuranceService} from "../../services/insurance";
import {createNotification} from "../../utils/notificationManager";
import {batchService} from "../../services/batch";
import {formatDate} from "../../utils/date";

class ManageBatches extends React.Component {

    state = {
        errors: [],
        name: '',
        trn: '',
        depositDate: '',
        totalAmount: '',
        insurance: {},
        paymentMode: '',
    };

    componentDidMount() {
        const batchId = this.props.location.state ? this.props.location.state.batchId : '';
        if (batchId) {
            batchService
                .getBatchDetail(batchId)
                .then(response => {
                    this.setState({
                        name: response.data.name,
                        trn: response.data.trn,
                        depositDate: Date.parse(formatDate(response.data.date)),
                        totalAmount: response.data.total,
                        insurance: {
                            label: response.data.insurance ? response.data.insurance.description : '',
                            value: response.data.insurance ? response.data.insurance.id : '',
                        },
                        paymentMode: response.data.payment_mode,
                    });
                })
        }
    }

    cancel = () => {
        this.props.history.push(APP_ROUTES.BATCH_DASHBOARD);
    };

    submitBatch = () => {
        const { createBatch, updateBatch} = this.props;
        const {
            name,
            trn,
            depositDate,
            totalAmount,
            insurance,
            paymentMode,
        } = this.state;

        const request = {
            name,
            trn,
            date: formatDate(depositDate),
            total: totalAmount,
            insurance_id: insurance.value,
            payment_mode: paymentMode,
            is_active: '1',
        };

        const batchId = this.props.location.state ? this.props.location.state.batchId : '';

        if (batchId) {
            const payload = {...request, 'id': batchId};
            updateBatch(payload)
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.BATCH_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        //this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update batch ${err.error.response.data.message}`);
                });
        } else {
            createBatch(request)
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.BATCH_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create batch ${err.error.response.data.message}`);
                });
        }
    };

    loadInsurance = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }
        options = {...options, api_type: 'react' };
        return insuranceService
            .getInsurances({ params: options })
            .then(response => {
                const options = response.data.map(insurance => (
                    {
                        value: insurance.id,
                        label: `${insurance.description} (${insurance.address1})`,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    render() {
        const batchId = this.props.location.state ? this.props.location.state.batchId : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar />
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {batchId &&
                                <h2 className="heading-custom">Edit Batch</h2>
                            }
                            {!batchId &&
                                <h2 className="heading-custom">Add Batch</h2>
                            }
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Name</label>
                            <input
                                type="text"
                                className='code-input'
                                value={this.state.name}
                                onChange={event => this.setState({name: event.target.value})}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Batch TRN (Check number, EFT Trace number, etc)</label>
                            <input
                                type="text"
                                className='code-input'
                                value={this.state.trn}
                                onChange={event => this.setState({trn: event.target.value})}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Deposit Date</label>
                            <br/>
                            <DatePicker
                                isClearable
                                selected={this.state.depositDate}
                                onChange={date => this.setState({depositDate: date})}
                                className="date-picker"
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Total Monetary Amount of Batch</label>
                            <input
                                type="text"
                                className='code-input'
                                value={this.state.totalAmount}
                                onChange={event => this.setState({totalAmount: event.target.value})}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Insurance/Payment Patient</label>
                            <AsyncPaginate
                                placeholder="-- Select Speciality --"
                                value={this.state.insurance}
                                loadOptions={this.loadInsurance}
                                onChange={value => this.setState({ insurance: value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Payment Mode</label>
                            <select
                                className='code-input'
                                onChange={event => this.setState({paymentMode: event.target.value})}
                                value={this.state.paymentMode}>
                                <option value="">-- Please Select Payment Mode --</option>
                                <option value="check">Check</option>
                                <option value="credit card">Credit Card</option>
                                <option value="cash">Cash</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                    </div>
                    <div className="filter-footer">
                        {batchId &&
                        <button
                            onClick={this.submitBatch}
                            className="filter-save-button">
                            Update
                        </button>
                        }
                        {!batchId &&
                        <button
                            onClick={this.submitBatch}
                            className="filter-save-button">
                            Submit
                        </button>
                        }
                        <button
                            onClick={this.cancel}
                            className="filter-cancel-button">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
    )}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createBatch,
            updateBatch
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageBatches));