import { apiClient } from "./apiClients";
import { API_CONSTANTS } from "../constants/api";
import { handleError, handleResponse } from "./GenericResponseHandler";

function getClaims(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_CLAIMS, options)
        .then(handleResponse)
        .catch(handleError);
}

function sendInvoice(options) {
    return apiClient()
        .post(API_CONSTANTS.SEND_INVOICE, options)
        .then(handleResponse)
        .catch(handleError);
}

function createInvoice(options) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_INVOICE, options)
        .then(handleResponse)
        .catch(handleError);
}

export const claimsService = {
    getClaims,
    sendInvoice,
    createInvoice,
}