import React from 'react';
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchSurgeons } from '../../actions/surgeons';
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {SURGEON_LISTING_COLUMNS, APP_ROUTES, LOCAL_STORAGE_KEYS} from "../../constants/common";
import SurgeonListingTable from "../atom/SurgeonListingTable";
import { surgeonService } from "../../services/surgeons";

class SurgeonDashboard extends React.Component {
    static propTypes = {
        totalRecords: PropTypes.number.isRequired,
    };

    state = {
        loading: false,
        currentPage: 1,
        searchByName: '',
        searched: false,
        searchedSurgeons: [],
        searchedTotalRecords: 0,
    };

    componentWillMount() {
        this.setState({loading: true});
        const options = {
            page: this.state.currentPage,
        };
        this.props.fetchSurgeons({ params: options })
            .then(res => {
                this.setState({loading: false});
            });
    }

    setLoading = (flag) => {
        this.setState({loading: flag});
    };

    setCurrentPage = (num) => {
        this.setState({currentPage: num});
    };

    goToPage = (page) => {
        this.setLoading(true);
        this.setCurrentPage(page);

        const options = {
            page: page,
        };
        this.props.fetchSurgeons({ params: options })
            .then(res => {
                this.setLoading(false);
            });
    };

    showAdd = () => this.props.history.push(APP_ROUTES.ADD_SURGEON);

    showManageSurgeon = surgeonId => this.props.history.push({
        pathname: APP_ROUTES.EDIT_SURGEON,
        state: {
            activePage: this.props.activePage || this.props.location.state.activePage,
            surgeonId,
        }
    });

    deleteSurgeon = (surgeon) => {
        surgeonService
            .deleteSurgeon(surgeon)
            .then(response => {
                this.goToPage(1);
            });
    };

    list = () => {
        const { surgeons = [] } = this.props;
        return surgeons.map(surgeon => {
            return [
                surgeon.id,
                `${surgeon.first_name ? surgeon.first_name : ''} ${surgeon.last_name ? surgeon.last_name : ''}`.toUpperCase(),
                surgeon.npi,
                surgeon.children ? surgeon.children.address  : '',
                surgeon.children ? surgeon.children.phone : '',
                surgeon.id,
            ];
        });
    };

    searchSurgeons = () => {
        const { searchByName } = this.state;
        let options = {};

        if (searchByName) {
            options = {...options, search: searchByName };
        }

        this.setState({
            searched: true,
            loading: true
        });
        surgeonService
            .getSurgeons({params: options})
            .then(response => {
                this.setState({
                    searchedTotalRecords: parseInt(response.records),
                    searchedSurgeons: response.data.map(surgeon => {
                        return [
                            surgeon.id,
                            `${surgeon.first_name ? surgeon.first_name : ''} ${surgeon.last_name ? surgeon.last_name : ''}`.toUpperCase(),
                            surgeon.npi,
                            surgeon.children ? surgeon.children.address : '',
                            surgeon.children ? surgeon.children.phone : '',
                            surgeon.id,
                        ];
                    })
                });
                this.setState({loading: false});
            });
    }

    resetSurgeonSearch = () => {
        this.setState({
            searchByName: '',
            searchedSurgeons: [],
            searched: false,
            searchedTotalRecords: 0,
        });
    }

    render() {
        const activePage = this.props.activePage || this.props.location.state.activePage;
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        const {
            searched,
            searchedSurgeons,
            searchedTotalRecords
        } = this.state;
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={activePage} />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '755px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left">
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>Surgeons</h3>
                        </div>
                    </div>
                    { loggedInUser.isAddAccess && (
                        <div className="filter-footer-header">
                            <button
                                onClick={() => { this.showAdd(true) }}
                                className="filter-button">
                                Add New Surgeon
                            </button>
                        </div>
                    )}
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                    }}>
                        <div style={{
                            paddingLeft: '0',
                        }} className="col-md-3 mrgn-btm10">
                            <label>Surgeon Name</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.searchByName}
                                onChange={event => this.setState({ searchByName: event.target.value })}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <button
                                style={{
                                    marginTop: '32px',
                                    width: '100px',
                                    height: '38px',
                                    marginRight: '10px',
                                }}
                                onClick={this.searchSurgeons}
                                className="filter-save-button">
                                Search
                            </button>
                            <button
                                style={{
                                    marginTop: '32px',
                                    width: '100px',
                                    height: '38px',
                                }}
                                onClick={this.resetSurgeonSearch}
                                className="filter-save-button">
                                Reset
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="activities-header">
                            <SurgeonListingTable
                                isLoading={this.state.loading}
                                currentPage={this.state.currentPage}
                                goToPage={this.goToPage}
                                edit={this.showManageSurgeon}
                                delete={this.deleteSurgeon}
                                headColumn={SURGEON_LISTING_COLUMNS}
                                listing={searched && searchedSurgeons.length > 0 ? searchedSurgeons : this.list()}
                                totalRecords={searched && searchedTotalRecords > 0 ? searchedTotalRecords : this.props.totalRecords}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        surgeons: state.surgeonReducer.surgeons,
        totalRecords: state.surgeonReducer.totalSurgeon,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchSurgeons,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(SurgeonDashboard));