import { CLAIM } from '../constants/actions';

const initialState = {
    claims: [],
    totalClaims: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case CLAIM.FETCH_CLAIMS_SUCCESS:
            return {
                ...state,
                claims: action.data.data,
                totalClaims: action.data.records,
            };
        case CLAIM.CLEAN_SEARCH:
            return {
                ...state,
                claims: [],
                totalClaims: 0,
            };
        default:
            return state;
    }
}