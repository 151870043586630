import React from 'react';
import {
    APP_ROUTES,
    ASSISTANTS,
    LOCAL_STORAGE_KEYS,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import AssistantListingTable from "../atom/AssistantListingTable";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {deleteAssistant, getRegionAssistants} from "../../actions/assistant";
import {assistantService} from "../../services/assistants";

class AssistantDashboard extends React.Component {

    state = {
        loading: false,
        currentPage: 1,
        searchByName: '',
        searched: false,
        searchedAssistants: [],
        searchedTotalRecords: 0,
    };

    componentDidMount() {
        this.setState({loading: true});
        let regionId = this.props.regionId || this.props.location.state.regionId;
        // if(regionId != "undefined"){
        //     regionId = 0;
        //     // alert(regionId);
        // }else{
        //     // alert('1 Undefined');
        // }
        const options = {
            page: this.state.currentPage,
        };
        this.props.getRegionAssistants(regionId, {params: options})
            .then(res => {
                this.setState({loading: false});
            });
    }

    goToPage = (page) => {
        const regionId = this.props.regionId || this.props.location.state.regionId;
        this.setState({loading: true});
        const options = {
            page: page < 0 ? 1 : page,
        };
        this.setCurrentPage(options.page);
        this.props.getRegionAssistants(regionId, { params: options })
            .then(res => {
                this.setState({loading: false});
            });
    };

    setCurrentPage = (num) => {
        this.setState({currentPage: num});
    };

    showAdd = () => this.props.history.push({
        pathname: APP_ROUTES.ADD_ASSISTANT,
        state: {
            activePage: 'surgical_assistants',
            regionId: this.props.regionId || this.props.location.state.regionId,
        }
    });

    resetAssistantSearch = () => {
        this.setState({
            searchByName: '',
            searchedAssistants: [],
            searched: false,
            searchedTotalRecords: 0,
        });
    }

    edit = assistantId => this.props.history.push({
        pathname: APP_ROUTES.EDIT_ASSISTANT,
        state: {
            activePage: this.props.activePage || this.props.location.state.activePage,
            assistantId,
            regionId: this.props.regionId || this.props.location.state.regionId,
        }
    });

    searchAssistants = () => {
        const { searchByName } = this.state;
        let options = {};
        const regionId = this.props.regionId || this.props.location.state.regionId;

        if (searchByName) {
            options = {...options, search: searchByName };
        }

        this.setState({
            searched: true,
            loading: true
        });
        assistantService
            .getRegionAssistants(regionId, {params: options})
            .then(response => {
                this.setState({
                    searchedTotalRecords: parseInt(response.records),
                    searchedAssistants: response.data.map(assistant => {
                        return [
                            assistant.id,
                            `${assistant.name} ${assistant.last_name}`,
                            assistant.email,
                            assistant.type,
                            assistant.is_active === '1' ? 'Active' : 'InActive',
                            assistant.id,
                        ];
                    })
                });
                this.setState({loading: false});
            });
    }

    list = () => {
        const { assistants = [] } = this.props;
        return assistants.map(assistant => {
            return [
                assistant.id,
                `${assistant.name} ${assistant.last_name}`,
                assistant.email,
                assistant.type,
                assistant.is_active === '1' ? 'Active' : 'InActive',
                assistant.id,
            ];
        });
    };

    delete = (assistant) => {
        this.props
            .deleteAssistant(assistant)
            .then(response => {
                this.goToPage(1);
            });
    };

    render() {
        const activePage = this.props.activePage || this.props.location.state.activePage;
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        const {
            searched,
            searchedAssistants,
            searchedTotalRecords
        } = this.state;
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={activePage} />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '755px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left">
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>Manage Assistants Users</h3>
                        </div>
                    </div>
                    { loggedInUser.isAddAccess && (
                        <div className="filter-footer-header">
                            <button
                                onClick={() => { this.showAdd(true) }}
                                className="filter-button">
                                Add New Assistant
                            </button>
                        </div>
                    )}
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                    }}>
                        <div style={{
                            paddingLeft: '0',
                        }} className="col-md-3 mrgn-btm10">
                            <label>User Name</label>
                            <input
                                type="text"
                                className="code-input"
                                value={this.state.searchByName}
                                onChange={event => {
                                    this.setState({ searchByName: event.target.value },
                                        () => {
                                            if (this.state.searchByName.length >= 3) {
                                                this.searchAssistants();
                                            } else if (this.state.searchByName.length === 0) {
                                                this.resetAssistantSearch();
                                            }
                                        }
                                    )
                                }}
                            />
                        </div>
                        <div className="col-md-3 mrgn-btm10">
                            <button
                                style={{
                                    marginTop: '32px',
                                    width: '100px',
                                    height: '38px',
                                }}
                                onClick={this.resetAssistantSearch}
                                className="filter-save-button">
                                Reset
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="activities-header">
                            <AssistantListingTable
                                isLoading={this.state.loading}
                                currentPage={this.state.currentPage}
                                goToPage={this.goToPage}
                                delete={this.delete}
                                edit={this.edit}
                                headColumn={ASSISTANTS}
                                listing={searched && searchedAssistants.length > 0 ? searchedAssistants : this.list()}
                                totalRecords={searched && searchedTotalRecords > 0 ? searchedTotalRecords : this.props.totalRecords}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        assistants: state.assistantReducer.assistants,
        totalRecords: state.assistantReducer.totalAssistants,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getRegionAssistants,
            deleteAssistant,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AssistantDashboard));
