import { ACTIVITY } from '../constants/actions';

const initialState = {
    activities: [],
    totalActivities: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ACTIVITY.FETCH_ACTIVITIES_SUCCESS:
            return {
                ...state,
                activities: action.data.data,
                totalActivities: action.data.records,
            };
        case ACTIVITY.SUBMIT_ACTIVITY_SUCCESS:
            const activities = [ ...state.activities, action.data.data ];
            return {
                ...state,
                activities: activities,
            }
        case ACTIVITY.UPDATE_ACTIVITY_SUCCESS:
            const updatedActivities = [ ...state.activities, action.data.data ];
            return {
                ...state,
                activities: updatedActivities,
            }
        case ACTIVITY.DELETE_ACTIVITY_SUCCESS:
            const deletedActivities = [ ...state.activities, action.data.data ];
            return {
                ...state,
                activities: deletedActivities,
            }
        default:
            return state;
    }
}