import React from "react";
import {bindActionCreators} from "redux";
import {createOrganization, updateOrganization} from "../../actions/organization";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {createNotification} from "../../utils/notificationManager";
import {APP_ROUTES, NOTIFICATION_TYPES} from "../../constants/common";
import {organizationService} from "../../services/organizations";

const REQUIRED_FIELDS = {
    NAME: 'name',
    EMAIL: 'email',
    PHONE: 'phone',
    ADDRESS: 'address',
};

class ManageOrganization extends React.Component {

    state = {
        name: '',
        email: '',
        phone: '',
        address: '',
        isActive: '1',
        errors: [],
    };

    componentDidMount() {
        const organizationId = this.props.location.state ? this.props.location.state.organizationId : '';
        if (organizationId) {
            organizationService
                .getOrganizationDetail(organizationId)
                .then(response => {
                    this.setState({
                        name: response.data.org_name,
                        email: response.data.org_email,
                        phone: response.data.org_phone,
                        address: response.data.org_address,
                        isActive: response.data.is_active,
                    });
                })
        }
    }

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    validateOrganization = () => {
        const {
            name,
            email,
            phone,
            address,
            errors,
        } = this.state;

        let isValid = true;

        if (name.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.NAME] });
            isValid = false;
        } else if (email.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.EMAIL] });
            isValid = false;
        } else if (phone.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.PHONE] });
            isValid = false;
        } else if (address.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.ADDRESS] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    };

    submitOrganization = () => {
        if (!this.validateOrganization()) {
            return;
        }
        const { createOrganization, updateOrganization } = this.props;
        const {
            name,
            email,
            phone,
            address,
            isActive,
        } = this.state;

        const request = {
            org_name: name,
            org_email: email,
            org_phone: phone,
            org_address: address,
            is_active: isActive,
        };

        const organizationId = this.props.location.state ? this.props.location.state.organizationId : '';
        if (organizationId) {
            const payload = {...request, 'id': organizationId};
            updateOrganization(payload)
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.ORGANIZATION_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update Organization ${err.error.response.data.message}`);
                });
        } else {
            createOrganization(request)
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push(APP_ROUTES.ORGANIZATION_DASHBOARD);
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create Organization ${err.error.response.data.message}`);
                });
        }
    };

    formatPhone = (str) => {
        if (str) {
            let phone = '';
            str = str.replace(/\D/g, '').substring(0,10);
            if (str.length < 3) {
                phone += str;
            } else if (str.length < 6) {
                phone += str.substring(0, 3) + '-';
                if (str.length > 3) {
                    phone += str.substring(3, str.length);
                }
            } else {
                phone += str.substring(0, 3) + '-' + str.substring(3, 6) + '-' + str.substring(6, 10);
            }
            return phone;
        }
        return str;
    };

    cancel = () => {
        this.props.history.push(APP_ROUTES.ORGANIZATION_DASHBOARD);
    };

    render() {
        const organizationId = this.props.location.state ? this.props.location.state.organizationId : '';
        const activePage = this.props.location.state ? this.props.location.state.activePage : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={activePage} />
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {organizationId &&
                            <h2 className="heading-custom">Edit Organization</h2>
                            }
                            {!organizationId &&
                            <h2 className="heading-custom">Add Organization</h2>
                            }
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Name<span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.NAME) ? 'error code-input' : 'code-input'}
                                value={this.state.name}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.NAME);
                                    this.setState({ name: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>EMAIL<span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.EMAIL) ? 'error code-input' : 'code-input'}
                                value={this.state.email}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.EMAIL);
                                    this.setState({ email: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>PHONE<span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.PHONE) ? 'error code-input' : 'code-input'}
                                value={this.formatPhone(this.state.phone)}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.PHONE);
                                    this.setState({ phone: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>ADDRESS<span className="required">*</span></label>
                            <input
                                type="text"
                                className={this.hasErrors(REQUIRED_FIELDS.ADDRESS) ? 'error code-input' : 'code-input'}
                                value={this.state.address}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.ADDRESS);
                                    this.setState({ address: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Active</label>
                            <select
                                className='code-input'
                                onChange={event => this.setState({ isActive: event.target.value })}
                                value={this.state.isActive}>
                                <option value="1"> Yes</option>
                                <option value="0"> No</option>
                            </select>
                        </div>
                    </div>
                    <div className="filter-footer">
                        {organizationId &&
                        <button
                            onClick={this.submitOrganization}
                            className="filter-save-button">
                            Update
                        </button>
                        }
                        {!organizationId &&
                        <button
                            onClick={this.submitOrganization}
                            className="filter-save-button">
                            Submit
                        </button>
                        }
                        <button
                            onClick={this.cancel}
                            className="filter-cancel-button">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createOrganization,
            updateOrganization
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageOrganization));