import {PRACTICE, PROCEDURE} from '../constants/actions';
import { procedureService } from '../services/procedures';
import {practiceService} from "../services/practices";

export const fetchProcedures = options => dispatch => {
    return new Promise((resolve, reject) => {
        procedureService
            .getProcedures(options)
            .then(response => {
                dispatch({
                    type: PROCEDURE.FETCH_PROCEDURES_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createProcedure = payload => dispatch => {
    return new Promise((resolve, reject) => {
        procedureService
            .createProcedure(payload)
            .then(response => {
                dispatch({
                    type: PROCEDURE.CREATE_PROCEDURE_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateProcedure = payload => dispatch => {
    return new Promise((resolve, reject) => {
        procedureService
            .updateProcedure(payload)
            .then(response => {
                dispatch({
                    type: PROCEDURE.UPDATE_PROCEDURE_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};