import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {APP_ROUTES, NOTIFICATION_TYPES, RECORDS_PER_PAGE} from "../../constants/common";
import {surgeonService} from "../../services/surgeons";
import {assistantService} from "../../services/assistants";
import {eventTypesService} from "../../services/eventTypes";
import DatePicker from "react-datepicker";
import {insuranceService} from "../../services/insurance";
import {createNotification} from "../../utils/notificationManager";

const REQUIRED_FIELDS = {
    MEMBER_NUMBER: 'insuranceMember',
    GROUP_NUMBER: 'insuranceGroup',
    INSURANCE_TYPE: 'insuranceType',
    INSURANCE_NAME: 'insuranceName',
    INSURANCE_FIRST_NAME: 'insuranceFirstName',
    INSURANCE_LAST_NAME: 'insuranceLastName',
    INSURANCE_DOB: 'insuranceDob',
};

class ManageClaimDetail extends React.Component {

    state = {
        toggleEventDetail: true,
        togglePatientDetail: false,
        toggleInsuranceInfo: false,
        toggleBillingDetail: false,
        claim: {},
        eventId: '',
        patientId: '',
        billId: '',
        surgeon: {},
        assistant: {},
        eventType: {},
        startDate: '',
        endDate: '',
        patientFirstName: '',
        patientLastName: '',
        patientDob: '',
        patientGender: '',
        patientAddress1: '',
        patientAddress2: '',
        patientCity: '',
        patientPhone: '',
        insuranceId: '',
        insuranceType: 'primary',
        insuranceName: {},
        insuranceDob: '',
        insuranceFirstName: '',
        insuranceLastName: '',
        insuranceGroup: '',
        insuranceMember: '',
        serviceLines: [],
        workerPatientCondition: '',
        employerName: '',
        dateOfInjury: '',
        illnessIndicator: '',
        errors: [],
    };

    componentDidMount() {
        const claimDetails = this.props.location.state ? this.props.location.state.claimDetails : '';
        const billId = this.props.location.state ? this.props.location.state.billId : '';
        const eventId = this.props.location.state ? this.props.location.state.eventId : '';

        claimDetails.map(claim => {

            if (claim.event_id === eventId && claim.old_bill_id === billId) {
                this.setState({
                    claim: claim,
                    eventId: claim.event_id,
                    patientId: claim.event_patient ? claim.event_patient.patient_id : '',
                    billId: claim.old_bill_id,
                    surgeon: {
                        label: claim.event_patient
                        && claim.event_patient.surgeon
                            ? `${claim.event_patient.surgeon.first_name} ${claim.event_patient.surgeon.last_name}`
                            : '',
                        value: claim.event_patient && claim.event_patient.surgeon ? claim.event_patient.surgeon.id : ''
                    },
                    assistant: {
                        label: claim.event_patient
                        && claim.event_patient.assistant
                            ? `${claim.event_patient.assistant.name} ${claim.event_patient.assistant.last_name}`
                            : '',
                        value: claim.event_patient && claim.event_patient.assistant ? claim.event_patient.assistant.id : ''
                    },
                    eventType: {
                        label: claim.event_patient && claim.event_patient.event_type ? claim.event_patient.event_type.event_type_name : '',
                        value: claim.event_patient && claim.event_patient.event_type ? claim.event_patient.event_type.id : '',
                    },
                    startDate: claim.event_patient ? Date.parse(claim.event_patient.event_start_date) : '',
                    endDate: claim.event_patient ? Date.parse(claim.event_patient.event_end_date) : '',

                    patientFirstName: claim.event_patient && claim.event_patient.patient
                        ? claim.event_patient.patient.first_name
                        : '',
                    patientLastName: claim.event_patient && claim.event_patient.patient
                        ? claim.event_patient.patient.last_name
                        : '',
                    patientDob: claim.event_patient && claim.event_patient.patient
                        ? Date.parse(claim.event_patient.patient.date_of_birth)
                        : '',
                    patientGender: claim.event_patient && claim.event_patient.patient
                        ? claim.event_patient.patient.gender
                        : '',
                    patientAddress1: claim.event_patient && claim.event_patient.patient
                        ? claim.event_patient.patient.address1
                        : '',
                    patientAddress2: claim.event_patient && claim.event_patient.patient
                        ? claim.event_patient.patient.address2
                        : '',
                    patientCity: claim.event_patient && claim.event_patient.patient
                        ? claim.event_patient.patient.city
                        : '',
                    patientPhone: claim.event_patient && claim.event_patient.patient
                        ? claim.event_patient.patient.phone_number
                        : '',
                    workerPatientCondition: claim.event_patient && claim.event_patient.patient
                        && claim.event_patient.patient.patient_insurance_data.workers_comp
                        ? claim.event_patient.patient.patient_insurance_data.workers_comp.patient_condition
                        : '',
                    employerName: claim.event_patient && claim.event_patient.patient
                        && claim.event_patient.patient.patient_insurance_data.workers_comp
                        ? claim.event_patient.patient.patient_insurance_data.workers_comp.employer_name
                        : '',
                    dateOfInjury: claim.event_patient && claim.event_patient.patient
                        && claim.event_patient.patient.patient_insurance_data.workers_comp
                        ? claim.event_patient.patient.patient_insurance_data.workers_comp.date_of_injury
                        : '',
                    illnessIndicator: claim.event_patient && claim.event_patient.patient
                        && claim.event_patient.patient.patient_insurance_data.workers_comp
                        ? claim.event_patient.patient.patient_insurance_data.workers_comp.illness_indicator
                        : '',
                    serviceLines: claim.service_lines ? claim.service_lines : [],
                }, () => {
                    this.insuranceDetail();
                }
             )}
        });
    }

    insuranceDetail = () => {
        const { claim, insuranceType } = this.state;
        let insurance = {};
        if (insuranceType === 'primary') {
            insurance = {
                id: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.primary
                    ? claim.event_patient.patient.patient_insurance_data.primary.id
                    : '',
                name: {
                    label: claim.event_patient
                    && claim.event_patient.patient
                    && claim.event_patient.patient.patient_insurance_data
                    && claim.event_patient.patient.patient_insurance_data.primary
                        ? claim.event_patient.patient.patient_insurance_data.primary.description
                        : '',
                    value: claim.event_patient
                    && claim.event_patient.patient
                    && claim.event_patient.patient.patient_insurance_data
                    && claim.event_patient.patient.patient_insurance_data.primary
                        ? claim.event_patient.patient.patient_insurance_data.primary.id
                        : '',
                },
                firstName: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.primary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.primary_subscriber.subscriber_first_name
                    : '',
                lastName: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.primary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.primary_subscriber.subscriber_last_name
                    : '',
                dob: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.primary_subscriber
                    ? Date.parse(claim.event_patient.patient.patient_insurance_data.primary_subscriber.subscriber_dob)
                    : '',
                group: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.primary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.primary_subscriber.subscriber_group_number
                    : '',
                member: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.primary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.primary_subscriber.subscriber_member_number
                    : '',
            }
        } else if (insuranceType === 'secondary') {
            insurance = {
                id: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.secondary
                    ? claim.event_patient.patient.patient_insurance_data.secondary.id
                    : '',
                name: {
                    label: claim.event_patient
                    && claim.event_patient.patient
                    && claim.event_patient.patient.patient_insurance_data
                    && claim.event_patient.patient.patient_insurance_data.secondary
                        ? claim.event_patient.patient.patient_insurance_data.secondary.description
                        : '',
                    value: claim.event_patient
                    && claim.event_patient.patient
                    && claim.event_patient.patient.patient_insurance_data
                    && claim.event_patient.patient.patient_insurance_data.secondary
                        ? claim.event_patient.patient.patient_insurance_data.secondary.id
                        : '',
                },
                firstName: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.secondary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.secondary_subscriber.subscriber_first_name
                    : '',
                lastName: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.secondary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.secondary_subscriber.subscriber_last_name
                    : '',
                dob: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.secondary_subscriber
                    ? Date.parse(claim.event_patient.patient.patient_insurance_data.secondary_subscriber.subscriber_dob)
                    : '',
                group: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.secondary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.secondary_subscriber.subscriber_group_number
                    : '',
                member: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.secondary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.secondary_subscriber.subscriber_member_number
                    : '',
            }
        } else if (insuranceType === 'tertiary') {
            insurance = {
                id: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.tertiary
                    ? claim.event_patient.patient.patient_insurance_data.tertiary.id
                    : '',
                name: {
                    label: claim.event_patient
                    && claim.event_patient.patient
                    && claim.event_patient.patient.patient_insurance_data
                    && claim.event_patient.patient.patient_insurance_data.tertiary
                        ? claim.event_patient.patient.patient_insurance_data.tertiary.description
                        : '',
                    value: claim.event_patient
                    && claim.event_patient.patient
                    && claim.event_patient.patient.patient_insurance_data
                    && claim.event_patient.patient.patient_insurance_data.tertiary
                        ? claim.event_patient.patient.patient_insurance_data.tertiary.id
                        : '',
                },
                firstName: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.tertiary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.tertiary_subscriber.subscriber_first_name
                    : '',
                lastName: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.tertiary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.tertiary_subscriber.subscriber_last_name
                    : '',
                dob: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.tertiary_subscriber
                    ? Date.parse(claim.event_patient.patient.patient_insurance_data.tertiary_subscriber.subscriber_dob)
                    : '',
                group: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.tertiary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.tertiary_subscriber.subscriber_group_number
                    : '',
                member: claim.event_patient
                && claim.event_patient.patient
                && claim.event_patient.patient.patient_insurance_data
                && claim.event_patient.patient.patient_insurance_data.tertiary_subscriber
                    ? claim.event_patient.patient.patient_insurance_data.tertiary_subscriber.subscriber_member_number
                    : '',
            }
        }

        this.setState({
            insuranceId: insurance.id,
            insuranceName: insurance.name,
            insuranceDob: insurance.dob,
            insuranceFirstName: insurance.firstName,
            insuranceLastName: insurance.lastName,
            insuranceGroup: insurance.group,
            insuranceMember: insurance.member,
        })
    };

    toggleEventDetail = () => {
        this.setState(prevState =>
            ({
                toggleEventDetail: !prevState.toggleEventDetail
            }));
    };

    togglePatientDetail = () => {
        this.setState(prevState =>
            ({
                togglePatientDetail: !prevState.togglePatientDetail
            }));
    };

    toggleInsuranceInfo = () => {
        this.setState(prevState =>
            ({
                toggleInsuranceInfo: !prevState.toggleInsuranceInfo
            }));
    };

    toggleBillingDetail = () => {
        this.setState(prevState =>
            ({
                toggleBillingDetail: !prevState.toggleBillingDetail
            }));
    };

    loadSurgeon = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return surgeonService
            .getSurgeons({ params: options })
            .then(response => {
                const options = response.data.map(surgeon => (
                    {
                        value: surgeon.id,
                        label: `${surgeon.first_name} ${surgeon.last_name}`,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadAssistant = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return assistantService
            .getAssistants({ params: options })
            .then(response => {
                const options = response.data.map(assistant => (
                    {
                        value: assistant.assistant_id,
                        label: `${assistant.NAME} ${assistant.last_name}`,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadEventTypes = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return eventTypesService
            .getEventTypes({ params: options })
            .then(response => {
                const options = response.data.map(eventType => (
                    {
                        value: eventType.id,
                        label: eventType.event_type_name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadInsurance = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }
        options = {...options, api_type: 'react' };
        return insuranceService
            .getInsurances({ params: options })
            .then(response => {
                const options = response.data.map(insurance => (
                    {
                        value: insurance.id,
                        label: insurance.description,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    editActivity = () => {
        this.props.history.push({
            pathname: APP_ROUTES.EDIT_ACTIVITY,
            state: {
                activePage: 'activities',
                activityId: this.state.eventId,
                screen: APP_ROUTES.CLAIMS_AND_COLLECTION,
            }
        });
    };

    editPatient = () => {
        this.props.history.push({
            pathname: APP_ROUTES.EDIT_PATIENT,
            state: {
                activePage: 'patients',
                patientId: this.state.patientId,
                screen: APP_ROUTES.CLAIMS_AND_COLLECTION,
            }
        });
    };

    editInsurance = () => {
        this.props.history.push({
            pathname: APP_ROUTES.EDIT_INSURANCE,
            state: {
                activePage: 'insurance',
                insId: this.state.insuranceId,
                screen: APP_ROUTES.CLAIMS_AND_COLLECTION,
            }
        });
    };

    editBilling = () => {
        this.props.history.push({
            pathname: APP_ROUTES.EDIT_BILL,
            state: {
                activePage: 'activities',
                billId: this.state.billId,
                eventId: this.state.eventId,
                screen: APP_ROUTES.CLAIMS_AND_COLLECTION,
            }
        });
    };

    renderServiceLines = () => {
        const { serviceLines } = this.state;
        return serviceLines.map((serviceLine, index) => (
            <>
                <div style={{
                    backgroundColor: "#e0e0e0",
                }} className="col-md-12 mrgn-btm10">
                    <label style={{
                        paddingTop: '6px',
                    }}>{`ServiceLine ${index + 1}`}</label>
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Service Date Start</label>
                    <br/>
                    <DatePicker
                        selected={Date.parse(serviceLine.line_start_date)}
                        disabled
                        className="date-picker"
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Service Date End</label>
                    <br/>
                    <DatePicker
                        disabled
                        selected={Date.parse(serviceLine.line_end_date)}
                        className="date-picker"
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>CPT</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={serviceLine.hcpcs_code}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Mod 1</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={serviceLine.modifier1}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Mod 2</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={serviceLine.modifier2}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Mod 3</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={serviceLine.modifier3}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Mod 4</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={serviceLine.modifier4}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Units</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={serviceLine.units}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Total</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={serviceLine.line_total}
                    />
                </div>
                <div className="col-md-2 mrgn-btm10">
                    <label>Discount</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={serviceLine.line_discount}
                    />
                </div>
                <div className="col-md-4 mrgn-btm10">
                    <label>CPT Description</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={serviceLine.code_description}
                    />
                </div>
                <div className="col-md-3 mrgn-btm10">
                    <label>Point of Diagnosis</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={serviceLine.points_to_diagnosis}
                    />
                </div>
                <div className="col-md-3 mrgn-btm10">
                    <label>Revenue Code</label>
                    <input
                        disabled
                        type="text"
                        className="code-input"
                        value={serviceLine.revenue_code}
                    />
                </div>
            </>
        ));
    };

    validateClaim = () => {
        const {
            insuranceType,
            insuranceFirstName,
            insuranceLastName,
            insuranceGroup,
            insuranceMember,
            insuranceDob,
            insuranceName,
            errors,
        } = this.state;
        let isValid = true;

        if(insuranceType.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.INSURANCE_TYPE] });
            isValid = false;
        } else if(insuranceFirstName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.INSURANCE_FIRST_NAME] });
            isValid = false;
        } else if(insuranceLastName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.INSURANCE_FIRST_NAME] });
            isValid = false;
        } else if(insuranceName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.INSURANCE_NAME] });
            isValid = false;
        } else if(insuranceGroup.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.GROUP_NUMBER] });
            isValid = false;
        } else if(insuranceMember.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.MEMBER_NUMBER] });
            isValid = false;
        } else if(insuranceDob.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.INSURANCE_DOB] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    };

    createClaim = () => {

        if (!this.validateClaim()) {
            return;
        }

        const eventId = this.props.location.state ? this.props.location.state.eventId : '';
        this.props.history.push({
            pathname: APP_ROUTES.CLAIM_FORM,
            state: {
                activePage: 'claims_and_collections',
                eventId,
                insuranceType: this.state.insuranceType,
            }
        })
    };

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    render() {
        const activePage = this.props.location.state ? this.props.location.state.activePage : '';
        const { claim } = this.state;
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col"
                     style={{
                         minHeight: '100vh',
                     }}>
                    <Sidebar activePage={activePage} />
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="top-padding">
                            <div className="page-title" onClick={this.toggleEventDetail}>
                                <h2 className="heading-custom">+/- Event Detail</h2>
                            </div>
                            { this.state.toggleEventDetail && (
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Event ID</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.eventId}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Surgeon</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.surgeon.label}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Assistant</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.assistant.label}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Event Type</label>
                                        <input
                                            type="text"
                                            className="code-input"
                                            value={this.state.eventType.label}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Start Date</label>
                                        <DatePicker
                                            disabled
                                            selected={this.state.startDate}
                                            className="date-picker"
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>End Date</label>
                                        <br/>
                                        <DatePicker
                                            disabled
                                            selected={this.state.endDate}
                                            className="date-picker"
                                        />
                                    </div>
                                    <div className="filter-footer">
                                        <button
                                            onClick={this.editActivity}
                                            className="filter-save-button">
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="top-padding">
                            <div className="page-title" onClick={this.togglePatientDetail}>
                                <h2 className="heading-custom">+/- Patient Info</h2>
                            </div>
                            {this.state.togglePatientDetail && (
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    <div className="col-md-3">
                                        <label>First Name</label>
                                        <input
                                            type="text"
                                            disabled
                                            value={this.state.patientFirstName}
                                            className="code-input"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label>Last Name</label>
                                        <input
                                            type="text"
                                            disabled
                                            value={this.state.patientLastName}
                                            className="code-input"
                                        />
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <label>DOB</label>
                                        <br/>
                                        <DatePicker
                                            disabled
                                            selected={this.state.patientDob}
                                            className="date-picker"
                                        />
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <label>Gender</label>
                                        <input
                                            type="text"
                                            disabled
                                            value={this.state.patientGender}
                                            className="code-input"
                                        />
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <label>Address 1</label>
                                        <input
                                            type="text"
                                            value={this.state.patientAddress1}
                                            className="code-input"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <label>Address 2</label>
                                        <input
                                            type="text"
                                            value={this.state.patientAddress2}
                                            className="code-input"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <label>City</label>
                                        <input
                                            type="text"
                                            value={this.state.patientCity}
                                            className="code-input"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-3 mrgn-btm10">
                                        <label>Phone</label>
                                        <input
                                            type="text"
                                            value={this.state.patientPhone}
                                            className="code-input"
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Patient's Condition Related To</label>
                                        <select
                                            value={this.state.workerPatientCondition}
                                            disabled
                                            className="filter-input"
                                            onChange={event => this.setState({ workerPatientCondition: event.target.value })}>
                                            <option value="">Select Condition</option>
                                            <option value="accident">Auto Accident</option>
                                            <option value="employment">Employment</option>
                                            <option value="other">Other Reason</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Employer Name</label>
                                        <input
                                            type="text"
                                            disabled
                                            className="code-input"
                                            value={this.state.employerName}
                                            onChange={event => this.setState({ employerName: event.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Date of Injury</label>
                                        <br/>
                                        <DatePicker
                                            isClearable
                                            disabled
                                            className="filter-input"
                                            selected={Date.parse(this.state.dateOfInjury)}
                                            onChange={date => this.setState({ dateOfInjury: date })}
                                        />
                                    </div>
                                    <div className="col-md-6 mrgn-btm10">
                                        <label>Illness Indicator</label>
                                        <select
                                            className="filter-input"
                                            disabled
                                            value={this.state.illnessIndicator}
                                            onChange={event => this.setState({ illnessIndicator: event.target.value })}>
                                            <option value="">Select Illness</option>
                                            <option value="illness">illness</option>
                                            <option value="indicator">indicator</option>
                                            <option value="injury">injury</option>
                                        </select>
                                    </div>
                                    <div className="filter-footer">
                                        <button
                                            onClick={this.editPatient}
                                            className="filter-save-button">
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="top-padding">
                            <div className="page-title" onClick={this.toggleInsuranceInfo}>
                                <h2 className="heading-custom">+/- Insurance Subscriber Info</h2>
                            </div>
                            {this.state.toggleInsuranceInfo && (
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Insurance Type <span className="required">*</span></label>
                                        <select
                                            className={this.hasErrors(REQUIRED_FIELDS.INSURANCE_TYPE) ? 'error filter-input' : 'filter-input'}
                                            value={this.state.insuranceType}
                                            onChange={event => this.setState({ insuranceType: event.target.value }, () => {
                                                this.insuranceDetail();
                                            })}>
                                            <option value="primary">Primary</option>
                                            <option value="secondary">Secondary</option>
                                            <option value="tertiary">Tertiary</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Insurance Name <span className="required">*</span></label>
                                        <input
                                            type="text"
                                            value={this.state.insuranceName.label}
                                            className={this.hasErrors(REQUIRED_FIELDS.INSURANCE_NAME) ? 'error code-input' : 'code-input'}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>First Name <span className="required">*</span></label>
                                        <input
                                            type="text"
                                            value={this.state.insuranceFirstName}
                                            className={this.hasErrors(REQUIRED_FIELDS.INSURANCE_FIRST_NAME) ? 'error code-input' : 'code-input'}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Last Name <span className="required">*</span></label>
                                        <input
                                            type="text"
                                            value={this.state.insuranceLastName}
                                            className={this.hasErrors(REQUIRED_FIELDS.INSURANCE_LAST_NAME) ? 'error code-input' : 'code-input'}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>DOB <span className="required">*</span></label>
                                        <br/>
                                        <DatePicker
                                            disabled
                                            selected={this.state.insuranceDob}
                                            className={this.hasErrors(REQUIRED_FIELDS.INSURANCE_DOB) ? 'error date-picker' : 'date-picker'}
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Member # <span className="required">*</span></label>
                                        <input
                                            type="text"
                                            value={this.state.insuranceMember}
                                            className={this.hasErrors(REQUIRED_FIELDS.MEMBER_NUMBER) ? 'error code-input' : 'code-input'}
                                            disabled
                                        />
                                    </div>
                                    <div className="col-md-4 mrgn-btm10">
                                        <label>Group #</label>
                                        <input
                                            type="text"
                                            value={this.state.insuranceGroup}
                                            className={this.hasErrors(REQUIRED_FIELDS.GROUP_NUMBER) ? 'error code-input' : 'code-input'}
                                            disabled
                                        />
                                    </div>
                                    <div className="filter-footer">
                                        <button
                                            onClick={this.editInsurance}
                                            className="filter-save-button">
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="top-padding">
                            <div className="page-title" onClick={this.toggleBillingDetail}>
                                <h2 className="heading-custom">+/- Billing Detail</h2>
                            </div>
                            {this.state.toggleBillingDetail && (
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {this.state.serviceLines && this.renderServiceLines()}
                                    <div className="filter-footer">
                                        <button
                                            onClick={this.editBilling}
                                            className="filter-save-button">
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="top-padding">
                            <div className="filter-footer">
                                <button
                                    type="submit"
                                    onClick={this.createClaim}
                                    className="scheduling-filter-button">
                                    Create Claim
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    null,
    null,
)(withRouter(ManageClaimDetail));