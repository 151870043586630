import {ORGANIZATION} from "../constants/actions";

const initialState = {
    organizations: [],
    totalOrganizations: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ORGANIZATION.FETCH_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organizations: action.data.data,
                totalOrganizations: action.data.records,
            };
        default:
            return state;
    }
}