import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Login as Authentication } from '@microsoft/mgt-react';

import { createNotification } from '../../utils/notificationManager';
import { authenticate, checkUser } from '../../actions/authentication';
import {
    APP_ROUTES,
    EMAIL_REGEX,
    LOCAL_STORAGE_KEYS,
    NOTIFICATION_TYPES,
} from '../../constants/common';
import '../../../sass/login.scss';

import companyLogo from '../../../assets/images/coremaxlogo-trans.png';
import backgroundVideo from '../../../assets/videos/video.mp4';
import Loader from "../atom/Loader";

class Login extends React.Component {
    static propTypes = {
        authenticate: PropTypes.func.isRequired,
        checkUser: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.loginRef = React.createRef();
        this.state = {
            email: '',
            buttonEnabled: false,
            showError: false,
            showDoesnotExistError: false,
            loading: localStorage.getItem(LOCAL_STORAGE_KEYS.OFFICE_365) === 'true',
        };
    }

    componentDidMount() {
        if (localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)) {
            localStorage.setItem(
                LOCAL_STORAGE_KEYS.OFFICE_365,
                'false',
            );
            this.props.history.push(APP_ROUTES.ACTIVITY_DASHBOARD);
        }
    }

    validate = (email) => {
        const pattern = new RegExp(EMAIL_REGEX);
        return pattern.test(email);
    };

    authenticate = () => {
        const { authenticate } = this.props;
        const user = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);
        const request = {"email": user,"api_type":"react"};

        if (!this.validate(user)) {
            this.setState({loading: false});
            createNotification(NOTIFICATION_TYPES.ERROR, 'Invalid Email');
            return;
        }

        authenticate(request)
            .then(res => {
                if (res.response.data.token) {
                    this.props.history.push(APP_ROUTES.DASHBOARD);
                } else {
                    createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                }
                window.location.reload();
            })
            .catch(err => {
                this.setState({loading: false});
            });
    };

    loginInitiated = () => {
        this.setState({loading: true});
        localStorage.setItem(
            LOCAL_STORAGE_KEYS.OFFICE_365,
            'true',
        );
    };

    loginCompleted = () => {
        if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER)) {
            this.authenticate();
        }
    };

    checkUser = (value) => {
        this.setState({
            email: value,
        },() => {
            const { checkUser } = this.props;
            if (!this.validate(value)) {
                this.setState({showError: true});
                return;
            }
            this.setState({showError: false});
            const request = {"email": value,"api_type":"react"};
            checkUser(request)
                .then(res => {
                    if (res.response.data.token) {
                        this.setState({
                            buttonEnabled: true,
                            showDoesnotExistError: false,
                        });
                    } else {
                        this.setState({
                            buttonEnabled: false,
                            showDoesnotExistError: true,
                        });
                    }
                })
                .catch(err => { });
        });
    };

    render() {
        return (
            <>
                {this.state.loading && (
                    <>
                        <Loader width='100%' />
                        <Authentication
                            className={this.state.buttonEnabled ? 'ms-login' : 'ms-login disabled'}
                            ref={this.loginRef}
                            loginCompleted={this.loginCompleted}
                            loginInitiated={this.loginInitiated}
                            loginFailed={this.loginFailed}
                            hidden
                        />
                    </>
                )}
                {!this.state.loading && (
                    <>
                        <video autoPlay loop muted className="video">
                            <source src={backgroundVideo} type='video/mp4' />
                        </video>
                        <div className="login-content">
                            <img
                                src={companyLogo}
                                alt="Coremax"
                            />
                            <br/>
                            <div className="login-input-container">
                                <input
                                    onChange={event => this.checkUser(event.target.value)}
                                    className="email-input"
                                    type="email"
                                    placeholder="Email Address"
                                />
                                {this.state.showError && (
                                    <span style={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        color: 'red',
                                        textAlign: "center",
                                    }}>Please enter a valid email</span>
                                )}
                                {this.state.showDoesnotExistError && (
                                    <span style={{
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        color: 'red',
                                        textAlign: "center",
                                    }}>Email not exist</span>
                                )}
                            </div>
                            <br/>
                            <Authentication
                                className={this.state.buttonEnabled ? 'ms-login' : 'ms-login disabled'}
                                ref={this.loginRef}
                                loginCompleted={this.loginCompleted}
                                loginInitiated={this.loginInitiated}
                                loginFailed={this.loginFailed}
                            />
                        </div>
                    </>
                )}
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            checkUser,
            authenticate,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(Login));

