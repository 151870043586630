import { USER } from '../constants/actions';

const initialState = {
    users: [],
    totalUsers: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case USER.FETCH_USER_SUCCESS:
            return {
                ...state,
                users: action.data.data,
                totalUsers: action.data.records,
            };
        case USER.CREATE_USER_SUCCESS:
            const createUser = [ ...state.users, action.data.data ];
            return {
                ...state,
                users: createUser,
            };
        case USER.UPDATE_USER_SUCCESS:
            const updateUser = [ ...state.users, action.data.data ];
            return {
                ...state,
                users: updateUser,
            }
        default:
            return state;
    }
}