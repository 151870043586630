import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { updateInvoicing } from "../../actions/invoicing";
import ReactTooltip from "react-tooltip";
import { procedureService } from "../../services/procedures";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import { createNotification } from "../../utils/notificationManager";
import { AsyncPaginate } from "react-select-async-paginate";
import DatePicker from "react-datepicker";
import LoadingOverlay from "react-loading-overlay";
import logo from "../../../assets/images/universal-logo.png";
import { invoicingService } from "../../services/invoicing";

import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { formatDate } from "../../utils/date";

import "react-datepicker/dist/react-datepicker.css";
import "../../../sass/manageactivities.scss";
import loaderImage from "../../../assets/images/CoureMax.gif";

const REQUIRED_FIELDS = {
  PATIENT: "patient",
  EVENT_TYPE: "eventType",
  SELECTED_PROCEDURES: "selectedProcedures",
  SURGEON: "surgeon",
  FACILITY: "facility",
  ASSISTANT: "assistant",
  ADMISSION_DATE: "admissionDate",
  START_TIME: "startTime",
};
const format = "h:mm a";

const now = moment().hour(0).minute(0);

class EditInvoicing extends React.Component {
  static propTypes = {
    updateInvoicing: PropTypes.func.isRequired,
    screen: PropTypes.string,
  };
  constructor(props) {
    super(props);

    this.state = {
      invoiceId: "",
      accountNumber: "",
      facilityInvoiceRequirements: "noneed",
      selectedProcedures: [],
      assistant: {},
      facility: {},
      dos: "",
      patient: {},
      insurance: "",
      surgeon: {},
      facility: {},
      status: {},
      procedure_ids: "",
      procedure_names: "",
      notes: "",
      modifiedBy: "",
      createdBy: "",
      createdUser: "",
      fields: {},
      loading: false,
      errors: [],
      fee: "",
      account_number: "",
    };
    this.cancel = "";
  }
  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  hasErrors = (field) => {
    return this.state.errors.includes(field);
  };

  getStatusName = (id) => {
    if (id === "NOT STARTED") {
      return "NOT STARTED";
    } else if (id === "SUBMITTED") {
      return "SUBMITTED";
    } else if (id === "CORRECTED CLAIM") {
      return "CORRECTED CLAIM";
    } else if (id === "PAPER CLAIM") {
      return "PAPER CLAIM";
    } else if (id === "RECONSIDERATION") {
      return "RECONSIDERATION";
    } else if (id === "FOLLOW-UP") {
      return "FOLLOW-UP";
    } else if (id === "APPEAL 1") {
      return "APPEAL 1";
    } else if (id === "APPEAL 2") {
      return "APPEAL 2";
    } else if (id === "HOLD TO BILL") {
      return "HOLD TO BILL";
    } else if (id === "BILL PATIENT") {
      return "BILL PATIENT";
    } else if (id === "BILL HOSPITAL") {
      return "BILL HOSPITAL";
    } else if (id === "BILL PHYSICIAN") {
      return "BILL PHYSICIAN";
    } else if (id === "REVIEW ADJUSTMENT") {
      return "REVIEW ADJUSTMENT";
    } else if (id === "CLOSED") {
      return "CLOSED";
    } else if (id === "CLOSED NON-BILL") {
      return "CLOSED NON-BILL";
    } else if (id === "CLOSED ADJUSTMENT") {
      return "CLOSED ADJUSTMENT";
    } else if (id === "CLOSED HC-2nd Scrub") {
      return "CLOSED HC-2nd Scrub";
    }
  };
  loadStatus = () => {
    const options = [
      {
        value: "NOT STARTED",
        label: "NOT STARTED",
      },
      {
        value: "SUBMITTED",
        label: "SUBMITTED",
      },
      {
        value: "CORRECTED CLAIM",
        label: "CORRECTED CLAIM",
      },
      {
        value: "PAPER CLAIM",
        label: "PAPER CLAIM",
      },
      {
        value: "RECONSIDERATION",
        label: "RECONSIDERATION",
      },
      {
        value: "FOLLOW-UP",
        label: "FOLLOW-UP",
      },
      {
        value: "APPEAL 1",
        label: "APPEAL 1",
      },
      {
        value: "APPEAL 2",
        label: "APPEAL 2",
      },
      {
        value: "HOLD TO BILL",
        label: "HOLD TO BILL",
      },
      {
        value: "BILL PATIENT",
        label: "BILL PATIENT",
      },
      {
        value: "BILL HOSPITAL",
        label: "BILL HOSPITAL",
      },
      {
        value: "BILL PHYSICIAN",
        label: "BILL PHYSICIAN",
      },
      {
        value: "REVIEW ADJUSTMENT",
        label: "REVIEW ADJUSTMENT",
      },
      {
        value: "CLOSED",
        label: "CLOSED",
      },
      {
        value: "CLOSED NON-BILL",
        label: "CLOSED NON-BILL",
      },
      {
        value: "CLOSED ADJUSTMENT",
        label: "CLOSED ADJUSTMENT",
      },
      {
        value: "CLOSED HC-2nd Scrub",
        label: "CLOSED HC-2nd Scrub",
      },

    ];

    return {
      options: options,
      hasMore: false,
    };
  };

  componentDidMount() {
    const activityId = this.props.location.state.activityId;
    if (activityId) {
      this.setLoading(true);
      invoicingService.getInvoiceDetail(activityId).then((response) => {
        this.setState(
          {
            account_number: response.data.patient.account_number,
            dos: response.data.dos,
            patient: response.data.patient,
            insurance: response.data.insurance,
            surgeon: response.data.surgeon,
            assistant: response.data.assistant,
            facility: response.data.facility,
            fee: response.data.fee,
            invoiceId: response.data.id,
            status: {
              label: this.getStatusName(response.data.status),
              value: response.data.status,
            },
            selectedProcedures: response.data.procedure.map((procedure) => {
              return {
                label: procedure.procedure_name,
                value: procedure.id,
                cptcode: procedure.cptcode,
                procedure_price: procedure.price,
              };
            }),
            procedure_ids: response.data.procedure_ids,
            procedure_names: response.data.procedure_names,
            facilityInvoiceRequirements: response.data.facility.invoice_req,
          },

          () => {
            this.setLoading(false);
          }
        );
      });
    }
  }
  resetErrors = (field) => {
    this.setState({
      errors: this.state.errors.filter((fieldName) => fieldName !== field),
    });
  };
  cancelNav = () => {
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    if (screen) {
      this.props.history.push(screen);
    } else {
      this.props.history.push(APP_ROUTES.INVOICING);
    }
  };
  onChangeProcedure = (value) => {
    this.resetErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES);
    this.setState({ selectedProcedures: value });
  };
  setProcedure = (value) => {
    this.resetErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES);
    this.setState((prevstate) => ({
      selectedProcedures: [...prevstate.selectedProcedures, value],
    }));
  };
  onChangeStatus = (value) => {
    this.setState({ status: value });
  };
  loadProcedures = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return procedureService
      .getProcedures({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((procedure) => ({
          value: procedure.id,
          label: procedure.procedure_name,
          procedure_price: procedure.price,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };
  validateInvoicing = () => {
    const { patient, surgeon, facility, assistant, errors } = this.state;
    let isValid = true;
    if (Object.keys(patient).length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.PATIENT] });
      isValid = false;
    } else if (Object.keys(surgeon).length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.SURGEON] });
      isValid = false;
    } else if (Object.keys(facility).length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.FACILITY] });
      isValid = false;
    } else if (Object.keys(assistant).length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.ASSISTANT] });
      isValid = false;
    }

    if (!isValid) {
      createNotification(
        NOTIFICATION_TYPES.ERROR,
        "Please select required values"
      );
    }

    return isValid;
  };
  updateInvoicing = () => {
    if (!this.validateInvoicing()) {
      return;
    }
    const { updateInvoicing } = this.props;
    const {
      invoiceId,
      accountNumber,
      assistant,
      facility,
      dos,
      patient,
      insurance,
      surgeon,
      status,
      modifiedBy,
      createdBy,
      createdUser,
      fee,
      selectedProcedures,
      account_number,
    } = this.state;

    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    const formData = new FormData();
    formData.append("id", invoiceId);
    formData.append("status", status.value);
    formData.append("assistant", assistant.name);
    formData.append("account_number", account_number);
    formData.append("fee", fee);
    formData.append(
      "procedure_id",
      selectedProcedures.map((procedure) => procedure.value).join()
    );
    formData.append(
      "procedure_name",
      selectedProcedures.map((procedure) => procedure.label).join()
    );
    updateInvoicing(formData).then((res) => {
      if (res.response.data.success) {
        this.props.history.push(APP_ROUTES.INVOICING_DASHBOARD);
      } else {
        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);

        // this.props.history.replaceState(this.state);
        // this.props.history.push({
        //   pathname: APP_ROUTES.INVOICING_DASHBOARD,
        //   state: {
        //     assistant,
        //   },
        // });
        // this.props.history.push(APP_ROUTES.INVOICING_DASHBOARD);

        setTimeout(function () {
          // alert("eee");

          window.location.href = APP_ROUTES.INVOICING_DASHBOARD;
        }, 2000);
        // window.location.href = APP_ROUTES.INVOICING_DASHBOARD;
      }
    });
  };
  render() {
    const activityId = this.props.location.state
      ? this.props.location.state.activityId
      : "";
    const activePage = "invoicing"; //this.props.location.state
    // ? this.props.location.state.activePage
    // : "invoicing";

    let invoiceProcedure;
    let procedure_heading;
    let procedure_value;
    let procedure_price;
    let account_number;
    account_number = (
      <div className="col-md-10 ">
        <input
          type="text"
          style={{ width:"150px" }}
          className={
            this.hasErrors(REQUIRED_FIELDS.account_number)
              ? "error code-input"
              : "code-input"
          }
          value={this.state.account_number}
          onChange={(event) => {
            this.resetErrors(REQUIRED_FIELDS.account_number);
            this.setState({ account_number: event.target.value });
          }}
        />
      </div>
    );
    if (this.state.price == 0 || this.state.price === undefined) {
      procedure_price = (
        <div className="col-md-20 ">
          <input
            type="text"
            style={{ width:"80px" }}
            className={
              this.hasErrors(REQUIRED_FIELDS.fee)
                ? "error code-input"
                : "code-input"
            }
            value={this.state.fee}
            onChange={(event) => {
              this.resetErrors(REQUIRED_FIELDS.fee);
              this.setState({ fee: event.target.value });
            }}
          />
        </div>
      );
    } else {
      procedure_price = this.state.price;
    }
    if (this.state.facilityInvoiceRequirements === "noneed") {
      invoiceProcedure = <div className="col-md-8"></div>;
    } else if (this.state.facilityInvoiceRequirements === "procedurename") {
      procedure_value = this.state.selectedProcedures.map(function (
        item,
        index
      ) {
        return (index ? ", " : "") + item.label;
      });
      procedure_heading = "Procedures : ";
      invoiceProcedure = (
        <div className="col-md-8" style={{ color: "black", float: "right" }}>
          <AsyncPaginate
            ref="procedure"
            className={
              this.hasErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES) ? "error" : ""
            }
            placeholder="Select Procedure..."
            value={this.state.selectedProcedures}
            loadOptions={this.loadProcedures}
            isMulti
            closeMenuOnSelect={false}
            onChange={this.onChangeProcedure}
          />
        </div>
      );
    } else if (this.state.facilityInvoiceRequirements === "cptcode") {
      procedure_value = this.state.selectedProcedures.map(function (
        item,
        index
      ) {
        return (index ? ", " : "") + item.value;
      });
      procedure_heading = "CPT Code: ";
      invoiceProcedure = (
        <div className="col-md-8" style={{ color: "black", float: "right" }}>
          <AsyncPaginate
            ref="procedure"
            className={
              this.hasErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES) ? "error" : ""
            }
            placeholder="Select Procedure..."
            value={this.state.selectedProcedures}
            loadOptions={this.loadProcedures}
            isMulti
            closeMenuOnSelect={false}
            onChange={this.onChangeProcedure}
          />
        </div>
      );
    } else if (this.state.facilityInvoiceRequirements === "both") {
      procedure_value = this.state.selectedProcedures.map(function (
        item,
        index
      ) {
        return (index ? ", " : "") + item.label + " " + item.value;
      });
      procedure_heading = "Procedure & CPT Code: ";
      invoiceProcedure = (
        <div className="col-md-8" style={{ color: "black", float: "right" }}>
          <AsyncPaginate
            ref="procedure"
            className={
              this.hasErrors(REQUIRED_FIELDS.SELECTED_PROCEDURES) ? "error" : ""
            }
            placeholder="Select Procedure..."
            value={this.state.selectedProcedures}
            loadOptions={this.loadProcedures}
            isMulti
            closeMenuOnSelect={false}
            onChange={this.onChangeProcedure}
          />
        </div>
      );
    }

    return (
      <div className="main_container">
        <div
          className="col-md-3 custom-sidebar-menu left_col"
          style={{
            minHeight: "145vh",
          }}
        >
          <Sidebar activePage={activePage} />
        </div>
        <TopNavbar />

        <div
          style={{
            minHeight: "830px",
          }}
          className="right-col"
        >
          <table
            style={{
              width: "100%",
              border: "0",
              margin: "margin: 0 auto",
            }}
          >
            <div className="filter-footer">
              <p
                style={{
                  width: "100%",
                  border: "0",
                  margin: "margin: 0 auto",
                  color: "red",
                  textAlign: "center",
                }}
              >
                Please Check Details CareFully and Click Invoice Button.
              </p>
              {/* <button
                type="submit"
                onClick={this.send}
                className="filter-footer-button"
              >
                Send Invoice
              </button> */}
              {
                <button
                  onClick={this.updateInvoicing}
                  className="filter-save-button"
                >
                  Update Invoice
                </button>
              }
              <button onClick={this.cancelNav} className="filter-cancel-button">
                Cancel
              </button>
            </div>
            <tr>
              <td
                style={{
                  height: "90px",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                <img src={logo} width="200" height="68" alt="" />
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #cfcfcf",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          width: "3%",
                        }}
                      >
                        <strong>Address:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          width: "39.5%",
                        }}
                      >
                        1 Sugar Creek Center Blvd. Suite 618 Sugar Land, Texas
                        77478
                      </td>
                      <td>
                        <div
                          className="col-md-4"
                          style={{ color: "black", float: "right" }}
                        >
                          <AsyncPaginate
                            placeholder="Select Status..."
                            value={this.state.status}
                            loadOptions={this.loadStatus}
                            onChange={this.onChangeStatus}
                          />
                        </div>
                        {invoiceProcedure}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #cfcfcf",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                        }}
                      >
                        <strong>Bill To:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                        }}
                      >
                        {this.state.facility.facility_name}
                      </td>
                      {/* <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        <strong>Invoice #:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        {1234}
                      </td> */}
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "right",
                        }}
                      >
                        <strong>Date:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        {formatDate(new Date())}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #cfcfcf",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                        }}
                      >
                        <strong>Payable to:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                        }}
                      >
                        Universal Surgical Assistants
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        <strong>Email:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                        }}
                      >
                        accounting@universalsa.com
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "right",
                        }}
                      >
                        <strong>Phone:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        832-655-4141
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #cfcfcf",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        colSpan="5"
                        style={{
                          background: "#4c638c",
                          color: "#fff",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "22px",
                          fontWeight: "300",
                          height: "45px",
                          verticalAlign: "middle",
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;Invoice
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #cfcfcf",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>Account #</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>DOS</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>Patient</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>DOB</strong>
                      </th>

                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>Insurance</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>Assistant</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>Surgeon</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>Facility</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>Total Bill</strong>
                      </th>
                    </tr>

                    <td>{account_number}</td>
                    <td>{this.state.dos}</td>
                    <td>{this.state.patient.first_name}</td>
                    <td>{this.state.patient.date_of_birth}</td>
                    <td>{this.state.insurance}</td>
                    <td>{this.state.assistant.name}</td>
                    <td>{this.state.surgeon.first_name}</td>
                    <td>{this.state.facility.facility_name}</td>
                    <td>{procedure_price}</td>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "#4c638c",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "30px",
                          fontWeight: "700",
                          height: "50px",
                          verticalAlign: "middle",
                          textAlign: "right",
                        }}
                      >
                        Total : ${this.state.fee}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <tbody
                    style={{
                      borderTop: "2px solid #cfcfcf",
                      paddingTop: "10px",
                    }}
                  >
                    <tr>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>{procedure_heading}</strong>
                      </th>
                      <td>{procedure_value}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#4c638c",
                  fontFamily: "Segoe, Segoe UI, Verdana",
                  fontSize: "20px",
                  fontWeight: "700",
                  height: "50px",
                  verticalAlign: "bottom",
                  textAlign: "center",
                }}
              >
                Thank you for your Business
              </td>
            </tr>
            <tr>
              <td
                style={{
                  height: "100px",
                  textAlign: "center",
                  verticalAlign: "bottom",
                }}
              >
                <img src={logo} width="200" height="68" alt="" />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#6f6f6f",
                  fontFamily: "Segoe, Segoe UI, Verdana",
                  fontSize: "13px",
                  fontWeight: "400",
                  height: "50px",
                  textAlign: "center",
                }}
              >
                support@coremaxsurgical.com <br />© Coremax Cloud
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateInvoicing,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(EditInvoicing));
