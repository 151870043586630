import {NOTIFICATION} from '../constants/actions';

const initialState = {
    notifications: [],
    totalNotifications: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATION.FETCH_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notifications: action.data.data,
                totalNotifications: action.data.records,
            };
        default:
            return state;
    }
}