import React from 'react';
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createPatient } from "../../actions/patients";

import { AsyncPaginate } from "react-select-async-paginate";
import {NOTIFICATION_TYPES, RECORDS_PER_PAGE} from "../../constants/common";
import { insuranceService } from "../../services/insurance";
import { createNotification } from "../../utils/notificationManager";
import DatePicker from "react-datepicker";

const REQUIRED_FIELDS = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    DOB: 'dob',
    PHONE: 'phone',
}

class AddPatient extends React.Component {
    static propTypes = {
        toggle: PropTypes.func.isRequired,
        setPatient: PropTypes.func.isRequired,
        createPatient: PropTypes.func.isRequired,
    };

    state = {
        firstName: '',
        lastName: '',
        dob: '',
        email: '',
        phone: '',
        gender: 'Male',
        primaryInsurance: {},
        secondaryInsurance: {},
        territoryInsurance: {},
        errors: [],
    };

    loadInsurance = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return insuranceService
            .getInsurances({ params: options })
            .then(response => {
                const options = response.data.map(insurance => (
                    {
                        value: insurance.id,
                        label: `${insurance.description} (${insurance.address1})`,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    addPatient = () => {
        const {
            createPatient,
            toggle,
            setPatient,
        } = this.props;

        if (!this.validatePatient()) {
            return;
        }

        const {
            firstName,
            lastName,
            dob,
            email,
            phone,
            gender,
            primaryInsurance,
            secondaryInsurance,
            territoryInsurance,
        } = this.state;

        const request = {
            first_name: firstName,
            last_name: lastName,
            date_of_birth: dob,
            email,
            gender,
            phone_number: phone,
            primary_insurance_id: primaryInsurance.value,
            secondary_insurance_id: secondaryInsurance.value,
            tertiary_insurance_id: territoryInsurance.value,
        };
        createPatient(request)
            .then(res => {
                setPatient({
                    label: `${res.response.data.first_name} ${res.response.data.last_name}`,
                    value: parseInt(res.response.data.id)
                });
                toggle(false);
            })
            .catch(err => {
                createNotification(NOTIFICATION_TYPES.ERROR, `Unable to Create Patient ${err.message}`);
            });
    };

    handlePhoneChange = (str) => {
        let phone = '';
        str = str.replace(/\D/g, '').substring(0,10);
        if (str.length < 3) {
            phone += str;
        } else if (str.length < 6) {
            phone += str.substring(0, 3) + '-';
            if (str.length > 3) {
                phone += str.substring(3, str.length);
            }
        } else {
            phone += str.substring(0, 3) + '-' + str.substring(3, 6) + '-' + str.substring(6, 10);
        }

        this.setState({phone: phone});
    }

    validatePatient = () => {
        const {
            firstName,
            lastName,
            dob,
            phone,
            errors,
        } = this.state;
        let isValid = true;

        if (firstName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.FIRST_NAME] });
            isValid = false;
        } else if (lastName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.LAST_NAME] });
            isValid = false;
        } else if (dob.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.DOB] });
            isValid = false;
        } else if (phone.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.PHONE] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    }

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    render() {
        return (
            <>
                <div className="col-md-4 mrgn-btm10">
                    <label>First Name <span className="required">*</span></label>
                    <input
                        type="text"
                        className={this.hasErrors(REQUIRED_FIELDS.FIRST_NAME) ? 'error code-input' : 'code-input'}
                        value={this.state.firstName}
                        onChange={event => {
                            this.resetErrors(REQUIRED_FIELDS.FIRST_NAME);
                            this.setState({ firstName: event.target.value })
                        }}
                    />
                </div>
                <div className="col-md-4 mrgn-btm10">
                    <label>Last Name <span className="required">*</span></label>
                    <input
                        type="text"
                        className={this.hasErrors(REQUIRED_FIELDS.LAST_NAME) ? 'error code-input' : 'code-input'}
                        value={this.state.lastName}
                        onChange={event => {
                            this.resetErrors(REQUIRED_FIELDS.LAST_NAME);
                            this.setState({lastName: event.target.value})
                        }}
                    />
                </div>
                <div className="col-md-4 mrgn-btm10">
                    <label>DOB(MM/DD/YYYY) <span className="required">*</span></label>
                    <DatePicker
                        isClearable
                        selected={this.state.dob}
                        onChange={date => {
                            this.resetErrors(REQUIRED_FIELDS.DOB);
                            this.setState({ dob: date })
                        }}
                        className={this.hasErrors(REQUIRED_FIELDS.DOB) ? 'error date-picker' : 'date-picker'}
                    />
                </div>
                <div className="col-md-4 mrgn-btm10">
                    <label>Email Address</label>
                    <input
                        type="text"
                        className="code-input"
                        value={this.state.email}
                        onChange={event => this.setState({ email: event.target.value })}
                    />
                </div>
                <div className="col-md-4 mrgn-btm10">
                    <label>Phone Number <span className="required">*</span></label>
                    <input
                        type="text"
                        className={this.hasErrors(REQUIRED_FIELDS.PHONE) ? 'error code-input' : 'code-input'}
                        value={this.state.phone}
                        onChange={event => {
                            this.resetErrors(REQUIRED_FIELDS.PHONE);
                            this.handlePhoneChange(event.target.value)
                        }}
                    />
                </div>
                <div className="col-md-4 mrgn-btm10">
                    <label>Gender</label>
                    <select
                        className="filter-input"
                        onChange={event => this.setState({ gender: event.target.value })}>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
                <div className="col-md-12 mrgn-btm10">
                    <label>Primary Insurance</label>
                    <AsyncPaginate
                        placeholder="-- Select Primary Insurance --"
                        value={this.state.primaryInsurance}
                        loadOptions={this.loadInsurance}
                        onChange={value => this.setState({ primaryInsurance: value })}
                    />
                </div>
                <div className="col-md-12 mrgn-btm10">
                    <label>Secondary Insurance</label>
                    <AsyncPaginate
                        placeholder="-- Select Secondary Insurance --"
                        value={this.state.secondaryInsurance}
                        loadOptions={this.loadInsurance}
                        onChange={value => this.setState({ secondaryInsurance: value })}
                    />
                </div>
                <div className="col-md-12 mrgn-btm10">
                    <label>Territory Insurance</label>
                    <AsyncPaginate
                        placeholder="-- Select Territory Insurance --"
                        value={this.state.territoryInsurance}
                        loadOptions={this.loadInsurance}
                        onChange={value => this.setState({ territoryInsurance: value })}
                    />
                </div>
                <div style={{borderBottom: "1px solid #ccc"}} className="col-md-12 mrgn-btm10">
                    <button
                        onClick={() => this.props.toggle(false)}
                        className="filter-cancel-button add-new-button mrgn-btm10">
                        Cancel
                    </button>
                    <button
                        onClick={this.addPatient}
                        className="filter-save-button add-new-button mrgn-btm10">
                        Create
                    </button>
                </div>
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createPatient
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(AddPatient));