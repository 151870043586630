import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import {API_CONSTANTS} from "../constants/api";

function getPatients(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_PATIENTS, options)
        .then(handleResponse)
        .catch(handleError);
}

function createPatient(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_PATIENT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updatePatient(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_PATIENT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deletePatient(patient) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_PATIENT + patient)
        .then(handleResponse)
        .catch(handleError);
}

function getPatientDetail(patient) {
    return apiClient()
        .get(API_CONSTANTS.PATIENT_DETAIL + patient)
        .then(handleResponse)
        .catch(handleError);
}

function getPatientActivity(patient) {
    return apiClient()
        .get(API_CONSTANTS.PATIENT_ACTIVITY + patient)
        .then(handleResponse)
        .catch(handleError);
}

function searchPatient(filters) {
    return apiClient()
        .get(API_CONSTANTS.SEARCH_PATIENT, filters)
        .then(handleResponse)
        .catch(handleError);
}

export const patientService = {
    getPatients,
    getPatientActivity,
    createPatient,
    updatePatient,
    deletePatient,
    getPatientDetail,
    searchPatient,
}