import { INVOICING } from '../constants/actions';
import { invoicingService } from '../services/invoicing';

export const fetchInvoicing = options => dispatch => {
    return new Promise((resolve, reject) => {
        invoicingService
            .getInvoicing(options)
            .then(response => {
                dispatch({
                    type: INVOICING.FETCH_INVOICING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const submitInvoicing = payload => dispatch => {
    return new Promise((resolve, reject) => {
        invoicingService
            .submitInvoicing(payload)
            .then(response => {
                dispatch({
                    type: INVOICING.SUBMIT_INVOICING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateInvoicing = payload => dispatch => {
    return new Promise((resolve, reject) => {
        invoicingService
            .updateInvoicing(payload)
            .then(response => {
                dispatch({
                    type: INVOICING.UPDATE_INVOICING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const deleteActivity = options => dispatch => {
    return new Promise((resolve, reject) => {
        invoicingService
            .deleteActivity(options)
            .then(response => {
                dispatch({
                    type: INVOICING.DELETE_INVOICING_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};