import { DASHBOARD } from '../constants/actions';

const initialState = {
    activities: [],
    totalActivities: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case DASHBOARD.FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                activities: action.data.data,
                totalActivities: action.data.records,
            };
        default:
            return state;
    }
}