import React from "react";
import PropTypes from "prop-types";
import {APP_ROUTES, RECORDS_PER_PAGE} from "../../constants/common";
import {faFile, faFileAlt} from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import {Link, withRouter} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ClaimsListingTable extends React.Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        currentPage: PropTypes.number.isRequired,
        goToPage: PropTypes.func.isRequired,
        headColumn: PropTypes.array.isRequired,
        listing: PropTypes.array.isRequired,
        events: PropTypes.array.isRequired,
        claimDetails: PropTypes.array.isRequired,
        totalRecords: PropTypes.number.isRequired,
    };

    renderHeadColumn = () => {
        const { headColumn } = this.props;

        return headColumn.map(column => (
            <th className="listing-table-head-column" style={{
                minWidth: '135px',
            }}>
                {column}
            </th>
        ));
    };

    renderPagination = () => {
        const { totalRecords, currentPage } = this.props;

        const totalPages = Math.ceil(totalRecords / RECORDS_PER_PAGE);

        const visiblePaginationPages = 6;

        const pageIndexes = [];

        const visiblePages = visiblePaginationPages / 2;
        if (totalPages > visiblePaginationPages + 2) {
            const firstPage = Math.min(currentPage, Math.max(totalPages - (visiblePages * 2), 0));
            for (let i = firstPage; i < firstPage + visiblePages; i++) {
                pageIndexes.push(i);
            }

            if (currentPage + visiblePages < totalPages - visiblePages) {
                pageIndexes.push(-1);
            }

            for (let i = totalPages - visiblePages; i < totalPages; i++) {
                pageIndexes.push(i);
            }
        } else {
            for (let i = 0; i < totalPages; i++) {
                pageIndexes.push(i);
            }
        }

        const pageLinks = pageIndexes.map((i, index) => {
            const button = <button
                disabled={currentPage === i}
                key={`pagination-button-${index}`}
                onClick={() => { return this.props.goToPage(i); }}
                className="listing-pagination-button">
                {i}
            </button>;

            const ellipsis = <div className="listing-pagination-button" key={`pagination-ellipsis-${index}`}>...</div>;
            return (
                -1 === i ? ellipsis : button
            );
        });
        return (
            <div className="listing-pagination">
                <div className="inner">
                    <button
                        onClick={() => { return this.props.goToPage(1); }}
                        disabled={1 === currentPage}
                        className="listing-pagination-button">
                        &lt;&lt; First
                    </button>
                    <button
                        onClick={() => { return this.props.goToPage(currentPage - 1); }}
                        disabled={1 === currentPage}
                        className="listing-pagination-button">
                        &lt; Previous
                    </button>
                    {pageLinks}
                    <button
                        onClick={() => { return this.props.goToPage(currentPage + 1); }}
                        disabled={currentPage === totalPages - 1}
                        className="listing-pagination-button">
                        Next &gt;
                    </button>
                    <button
                        onClick={() => { return this.props.goToPage(totalPages - 1); }}
                        disabled={currentPage === totalPages - 1}
                        className="listing-pagination-button">
                        Last &gt;&gt;
                    </button>
                </div>
            </div>
        );
    };

    navigateToEditBill = (billId, eventId) => this.props.history.push({
        pathname: APP_ROUTES.EDIT_BILL,
        state: {
            activePage: 'claims_and_collections',
            billId,
            eventId,
        }
    });

    showClaimDetails = (billId, eventId, claimDetails) => {
        this.props.history.push({
            pathname: APP_ROUTES.EDIT_CLAIM_AND_COLLECTION,
            state: {
                activePage: 'claims_and_collections',
                billId,
                eventId,
                claimDetails,
            }
        })
    };

    renderList = () => {
        const { listing = [] } = this.props;
        return listing.map(listRow => {
            const item = listRow.map((listItem, index) => {
                if (index === 0) {
                    return (
                        <td className="listing-table-column"
                            style={{
                                minWidth: '135px',
                                padding: '10px',
                                lineHeight: '1.1',
                            }}>
                            <Link
                                to={{
                                    pathname: APP_ROUTES.EDIT_BILL,
                                    state: {
                                        activePage: APP_ROUTES.ACTIVITY_DASHBOARD,
                                        billId: listItem,
                                        eventId: listRow[1],
                                        screen: APP_ROUTES.CLAIMS_AND_COLLECTION,
                                    }
                                }}>
                                {listItem}
                            </Link>
                        </td>
                    );
                } else if (index === 1) {
                    return (
                        <td className="listing-table-column"
                            style={{
                                minWidth: '135px',
                                padding: '10px',
                                lineHeight: '1.1',
                            }}>
                            <Link
                                to={{
                                    pathname: APP_ROUTES.EDIT_ACTIVITY,
                                    state: {
                                        activePage: APP_ROUTES.ACTIVITY_DASHBOARD,
                                        activityId: listItem,
                                        screen: APP_ROUTES.CLAIMS_AND_COLLECTION,
                                    }
                                }}>
                                {listItem}
                            </Link>
                        </td>
                    );
                } else if (index === 4) {
                    return (
                        <td className="listing-table-column"
                            style={{
                                minWidth: '135px',
                                padding: '10px',
                                lineHeight: '1.1',
                            }}>
                            <Link
                                to={{
                                    pathname: APP_ROUTES.EDIT_PATIENT,
                                    state: {
                                        activePage: APP_ROUTES.PATIENTS_DASHBOARD,
                                        patientId: listRow[2],
                                        screen: APP_ROUTES.CLAIMS_AND_COLLECTION,
                                    }
                                }}>
                                {listItem}
                            </Link>
                        </td>
                    );
                } else if (index === 12) {
                    return (
                        <td
                            className="listing-table-column"
                            style={{
                                minWidth: '135px',
                                padding: '10px',
                                lineHeight: '1.1',
                            }}>
                            <FontAwesomeIcon
                                icon={faFile}
                                size='lg'
                                color='#475f87'
                                onClick={() => this.showClaimDetails(listRow[0], listRow[1], this.props.claimDetails)}
                            />
                            <FontAwesomeIcon
                                style={{
                                    marginLeft: '10px',
                                }}
                                icon={faFileAlt}
                                size='lg'
                                color='#475f87'
                                onClick={() => this.navigateToEditBill(listRow[0], listRow[1])}
                            />
                        </td>
                    );
                } else {
                    return (
                        <td className="listing-table-column"
                            style={{
                                minWidth: '135px',
                                padding: '10px',
                                lineHeight: '1.1',
                            }}>
                            { listItem }
                        </td>
                    );
                }
            });

            return (
                <tr className="listing-table-rows">
                    {item}
                </tr>
            );
        });
    };

    render() {
        return (
            <div className="x_panel">
                <div className="x_content">
                    <div className="table-responsive">
                        <table className="listing-table">
                            <thead className="listing-table-head">
                            <tr>
                                {this.renderHeadColumn()}
                            </tr>
                            </thead>
                            {this.props.isLoading && (
                                <Loader width='280%' />
                            )}
                            <tbody>
                            {!this.props.isLoading && (
                                this.renderList()
                            )}
                            </tbody>
                        </table>
                    </div>
                    {this.props.totalRecords > 10 && this.props.listing.length > 0
                        ? this.renderPagination()
                        : <div style={{
                            width: '100%',
                            textAlign: 'center',
                            paddingTop: '2px',
                        }}>
                            { !this.props.isLoading && this.props.listing.length === 0 && (
                                <span className="noRecord">No Records Found</span>
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(ClaimsListingTable);