import React from "react";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import LoadingOverlay from 'react-loading-overlay';
import loaderImage from "../../../assets/images/CoureMax.gif";
import {APP_ROUTES, NOTIFICATION_TYPES} from "../../constants/common";
import {bindActionCreators} from "redux";
import {updatePosting} from "../../actions/batches";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {createNotification} from "../../utils/notificationManager";

class ManagePosting extends React.Component {

    state = {
        loading: false,
        paid: this.props.location.state.paid || '',
    };

    cancel = () => {
        this.props.history.push(APP_ROUTES.BATCH_DASHBOARD);
    };

    submitPosting = () => {
        const posting = this.props.location.state ? this.props.location.state.posting : '';
        const { updatePosting } = this.props;
        const request = {
            paid: this.state.paid,
        }
        updatePosting(request, posting)
            .then(res => {
                if (res.response.success) {
                    createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                    this.props.history.push(APP_ROUTES.BATCH_DASHBOARD);
                }
                this.setState({loading: false});
            })
            .catch(err => {
                this.setState({loading: false});
                createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update posting ${err.error.response.data.message}`);
            });
    };

    render() {
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar />
                </div>
                <TopNavbar />
                <LoadingOverlay
                    active={this.state.loading}
                    spinner={<img
                        style={{
                            width: '200px',
                        }}
                        src={loaderImage}
                        alt="loading..."
                    />}>
                    <div className="manage-activity-right-col">
                        <div className="activity-row">
                            <div className="page-title">
                                <h2 className="heading-custom">Edit Posting</h2>
                            </div>
                            <div className="col-md-6 mrgn-btm10">
                                <label>Paid Amount</label>
                                <input
                                    type="text"
                                    className="code-input"
                                    value={this.state.paid}
                                    onChange={event => this.setState({ paid: event.target.value })}
                                />
                            </div>
                            <div className="filter-footer">
                                <button
                                    onClick={this.submitPosting}
                                    className="filter-save-button">
                                    Submit
                                </button>
                                <button
                                    onClick={this.cancel}
                                    className="filter-cancel-button">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updatePosting,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManagePosting));