import React, { Profiler } from "react";
import { APP_ROUTES } from '../../constants/common';
import {
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
import { PrivateRoute } from '../../privateRoute';
import Login from '../ecosystems/Login';
import Register from '../ecosystems/Register';
import NotAuthorized from '../ecosystems/NotAuthorized';
import PageNotFound from '../ecosystems/404NotFound';
import ActivityDashboard from '../ecosystems/ActivityDashboard';
import PatientsDashboard from '../ecosystems/PatientsDashboard';
import ClaimsAndCollectionDashboard from '../ecosystems/ClaimsAndCollectionDashboard';
import SurgeonDashboard from '../ecosystems/SurgeonDashboard';
import FacilityDashboard from '../ecosystems/FacilityDashboard';
import NotificationDashboard from '../ecosystems/NotificationDashboard';
import RegionsDashboard from '../ecosystems/RegionsDashboard';
import FileDisplay from '../ecosystems/FileDisplay';
import AssistantDashboard from '../ecosystems/AssistantDashboard';
import UserDashboard from '../ecosystems/UserDashboard';
import ManageActivity from "../molecules/ManageActivity";
import ManageBatches from "../molecules/ManageBatches";
import ManageOrganization from "../molecules/ManageOrganization";
import ManagePatient from "../molecules/ManagePatient";
import ManageClaimDetail from "../molecules/ManageClaimDetail";
import ManageFacility from "../molecules/ManageFacility";
import ManageRegions from "../molecules/ManageRegions";
import ManageAssistant from "../molecules/ManageAssistant";
import ManageScanFlow from "../molecules/ManageScanFlow";
import ManageSurgeons from "../molecules/ManageSurgeons";
import ManagePaymentPosting from "../molecules/ManagePaymentPosting";
import ManagePosting from "../molecules/ManagePosting";
import Dashboard from "../ecosystems/Dashboard";
import ScanFlowDashboard from "../ecosystems/ScanFlowDashboard";
import BatchDashboard from "../ecosystems/BatchDashboard";
import ManageBilling from "../molecules/ManageBilling";
import ManageUsers from "../molecules/ManageUsers";
import ManageCaseLogs from "../molecules/ManageCaseLogs";
import ManageInvoice from "../molecules/ManageInvoice";
import ClaimInvoice from "../atom/ClaimInvoice";
import ClaimForm from "../atom/ClaimForm";
import CaseLogDashboard from "../ecosystems/CaseLogDashboard";
import InsuranceDashboard from "../ecosystems/InsuranceDashboard";
import ManageInsurance from "../molecules/ManageInsurance";
import PracticeDashboard from "../ecosystems/PracticeDashboard";
import ManagePractice from "../molecules/ManagePractice";
import ProcedureDashboard from "../ecosystems/ProcedureDashboard";
import OrganizationDashboard from '../ecosystems/OrganizationDashboard';
import ManageProcedure from "../molecules/ManageProcedure";
import AccountsDashboard from "../ecosystems/AccountsDashboard";
import AccountDetailDashboard from "../ecosystems/AccountDetailDashboard";
import Profile from "../ecosystems/Profile";
import InvoicingDashboard from '../ecosystems/InvoicingDashboard';
import ManageInvoicing from "../molecules/ManageInvoicing";
import EditInvoicing from "../molecules/EditInvoicing";
import RevenueSchedulerDashboard from "../ecosystems/RevenueSchedulerDashboard";
import ManageRevenueScheduler from "../molecules/ManageRevenueScheduler";


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={APP_ROUTES.REGISTER}>
          <Register />
        </Route>
        <Route exact path={APP_ROUTES.LOGIN}>
          <Login />
        </Route>
        <Route
            exact
            path={APP_ROUTES.FILE_DISPLAY}
            component={FileDisplay}
        />
        <Route exact path={APP_ROUTES.NOT_AUTHORIZED}>
          <NotAuthorized />
        </Route>
        <PrivateRoute
            exact
            path={APP_ROUTES.NOTIFICATION}
            component={NotificationDashboard}
            activePage={'notifications'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.PROFILE}
            component={Profile}
            activePage={'scheduling'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.REVENUE_SCHEDULER_DASHBOARD}
            component={RevenueSchedulerDashboard}
            activePage={'revenue-scheduler'}
        />
        
        <PrivateRoute
            exact
            path={APP_ROUTES.INVOICING}
            component={InvoicingDashboard}
            activePage={'invoicing'}
        />
          <PrivateRoute
            exact
            path={APP_ROUTES.ADD_INVOICE}
            component={ManageInvoicing}
            activePage={'invoicing'}
        />
        <PrivateRoute
            exact 
            path={APP_ROUTES.EDIT_INVOICING}
            component={EditInvoicing}
            activePage={'invoicing'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.DASHBOARD}
            component={Dashboard}
            activePage={'scheduling'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ACTIVITY_DASHBOARD}
            component={ActivityDashboard}
            activePage={'activities'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ADD_ACTIVITY}
            component={ManageActivity}
            activePage={'activities'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.EDIT_ACTIVITY}
            component={ManageActivity}
            activePage={'activities'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.PATIENTS_DASHBOARD}
            component={PatientsDashboard}
            activePage={'patients'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ADD_PATIENT}
            component={ManagePatient}
            activePage={'patients'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.EDIT_PATIENT}
            component={ManagePatient}
            activePage={'patients'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.PROCEDURE_DASHBOARD}
            component={ProcedureDashboard}
            activePage={'procedures'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ADD_PROCEDURE}
            component={ManageProcedure}
            activePage={'procedures'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.EDIT_PROCEDURE}
            component={ManageProcedure}
            activePage={'procedures'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.SURGEONS_DASHBOARD}
            component={SurgeonDashboard}
            activePage={'surgeons'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ADD_SURGEON}
            component={ManageSurgeons}
            activePage={'surgeons'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.EDIT_SURGEON}
            component={ManageSurgeons}
            activePage={'surgeons'}
        />
        <PrivateRoute
            exact path={APP_ROUTES.FACILITIES_DASHBOARD}
            component={FacilityDashboard}
            activePage={'facilities'}
        />
        <PrivateRoute
            exact path={APP_ROUTES.ADD_FACILITY}
            component={ManageFacility}
            activePage={'facilities'}
        />
        <PrivateRoute
            exact path={APP_ROUTES.EDIT_FACILITY}
            component={ManageFacility}
            activePage={'facilities'}
        />
        <PrivateRoute
            exact path={APP_ROUTES.SCAN_FLOW_DASHBOARD}
            component={ScanFlowDashboard}
            activePage={'scan-flow'}
        />
        <PrivateRoute
            exact path={APP_ROUTES.EDIT_SCAN_FLOW}
            component={ManageScanFlow}
            activePage={'scan-flow'}
        />
        <PrivateRoute
            exact path={APP_ROUTES.CASELOG_DASHBOARD}
            component={CaseLogDashboard}
            activePage={'pac-data'}
        />
        <PrivateRoute
            exact path={APP_ROUTES.ADD_CASELOG}
            component={ManageCaseLogs}
            activePage={'pac-data'}
        />
        <PrivateRoute
            exact path={APP_ROUTES.EDIT_CASELOG}
            component={ManageCaseLogs}
            activePage={'pac-data'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ADD_INSURANCE}
            component={ManageInsurance}
            activePage={'insurance'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.EDIT_INSURANCE}
            component={ManageInsurance}
            activePage={'insurance'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.INSURANCE_DASHBOARD}
            component={InsuranceDashboard}
            activePage={'insurance'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.USER_DASHBOARD}
            component={UserDashboard}
            activePage={'users'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ADD_USER}
            component={ManageUsers}
            activePage={'users'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.EDIT_USER}
            component={ManageUsers}
            activePage={'users'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.PRACTICE_DASHBOARD}
            component={PracticeDashboard}
            activePage={'practices'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ADD_PRACTICE}
            component={ManagePractice}
            activePage={'practices'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.EDIT_PRACTICE}
            component={ManagePractice}
            activePage={'practices'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ADD_BILL}
            component={ManageBilling}
            activePage={'activities'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.EDIT_BILL}
            component={ManageBilling}
            activePage={'activities'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.CLAIMS_AND_COLLECTION}
            component={ClaimsAndCollectionDashboard}
            activePage={'claims_and_collections'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.EDIT_CLAIM_AND_COLLECTION}
            component={ManageClaimDetail}
            activePage={'claims_and_collections'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.INVOICE_CLAIM_AND_COLLECTION}
            component={ClaimInvoice}
            activePage={'claims_and_collections'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.CLAIM_FORM}
            component={ClaimForm}
            activePage={'claims_and_collections'}
        />
        
        <PrivateRoute
            exact
            path={APP_ROUTES.REGIONS}
            component={RegionsDashboard}
            activePage={'regions'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ADD_REGION}
            component={ManageRegions}
            activePage={'regions'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.UPDATE_REGION}
            component={ManageRegions}
            activePage={'regions'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.SURGICAL_ASSISTANT}
            component={AssistantDashboard}
            activePage={'surgical_assistants'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.SURGICAL_ASSISTANT_DASHBOARD}
            component={AssistantDashboard}
            activePage={'surgical_assistants'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ADD_ASSISTANT}
            component={ManageAssistant}
            activePage={'surgical_assistants'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.EDIT_ASSISTANT}
            component={ManageAssistant}
            activePage={'surgical_assistants'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.BATCH_DASHBOARD}
            component={BatchDashboard}
            activePage={'Batch'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.BATCH_ADD}
            component={ManageBatches}
            activePage={'Batch'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.BATCH_UPDATE}
            component={ManageBatches}
            activePage={'Batch'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.PAYMENT_POSTING}
            component={ManagePaymentPosting}
            activePage={'Batch'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.UPDATE_PAYMENT_POSTING}
            component={ManagePosting}
            activePage={'Batch'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ACCOUNT_DASHBOARD}
            component={AccountsDashboard}
            activePage={'accounts'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ACCOUNT_INVOICE_DETAIL}
            component={AccountDetailDashboard}
            activePage={'accounts'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ACCOUNT_INVOICE_UPDATE}
            component={ManageInvoice}
            activePage={'accounts'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ORGANIZATION_DASHBOARD}
            component={OrganizationDashboard}
            activePage={'organizations'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.ADD_ORGANIZATION}
            component={ManageOrganization}
            activePage={'organizations'}
        />
        <PrivateRoute
            exact
            path={APP_ROUTES.EDIT_ORGANIZATION}
            component={ManageOrganization}
            activePage={'organizations'}
        />
        <PrivateRoute
            exact
            path="/"
            name="Home"
            component={Dashboard}
            activePage={'scheduling'}
        />
        <Route component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
