import {BACTHES} from '../constants/actions';

const initialState = {
    batches: [],
    totalBatches: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case BACTHES.FETCH_BATCHES_SUCCESS:
            return {
                ...state,
                batches: action.data.data,
                totalBatches: action.data.records,
            };
        case BACTHES.CREATE_BATCHES_SUCCESS:
            const batches = [ ...state.batches, action.data.data ];
            return {
                ...state,
                batches: batches,
            };
        case BACTHES.UPDATE_BATCHES_SUCCESS:
            const updatedBatches = [ ...state.batches, action.data.data ];
            return {
                ...state,
                batches: updatedBatches,
            }
        case BACTHES.CREATE_POSTING_SUCCESS:
            return state;
        case BACTHES.UPDATE_POSTING_SUCCESS:
            return state;
        default:
            return state;
    }
}