import React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import '../../../sass/listingtable.scss';
import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/delete.png";
import {APP_ROUTES, NOTIFICATION_TYPES, RECORDS_PER_PAGE} from "../../constants/common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPeopleArrows} from '@fortawesome/free-solid-svg-icons';
import Loader from "./Loader";
import {createNotification} from "../../utils/notificationManager";
import {bindActionCreators} from "redux";
import {impersonate} from "../../actions/authentication";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class UserListingTable extends React.Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        currentPage: PropTypes.number.isRequired,
        goToPage: PropTypes.func.isRequired,
        impersonate: PropTypes.func.isRequired,
        edit: PropTypes.func.isRequired,
        delete: PropTypes.func.isRequired,
        headColumn: PropTypes.array.isRequired,
        listing: PropTypes.array.isRequired,
        totalRecords: PropTypes.number.isRequired,
    };

    renderHeadColumn = () => {
        const { headColumn } = this.props;

        const header = headColumn.map((column, index) => {
            if (index === 1) {
                return (
                    <td style={{
                        textAlign: 'left',
                    }}
                        className="listing-table-head-column">
                        {column}
                    </td>
                );
            } else {
                return (
                    <th className="listing-table-head-column">
                        {column}
                    </th>
                );
            }
        });

        const actionElement = (
            <th className="listing-table-head-column">
                {'Action'}
            </th>
        );

        header.splice(headColumn.length + 1, 0, actionElement);

        return header;
    };

    edit = item => {
        this.props.edit(item);
    }

    delete = item => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.delete(item);
            }
        })
    }

    impersonate = (email) => {
        const { impersonate } = this.props;
        const request = {"email": email};

        impersonate(request)
            .then(res => {
                if (res.response.data.token) {
                    this.props.history.push({
                        pathname: APP_ROUTES.DASHBOARD,
                        state: {
                            activePage: 'activities',
                        }
                    });
                } else {
                    createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
                }
            })
            .catch(err => { });
    };

    renderList = () => {
        const { listing = [] } = this.props;
        return listing.length > 0 && listing.map(listRow => {
            const item = listRow.map((listItem, index) => {
                if (index === 1) {
                    return (
                        <td style={{
                            textAlign: 'left',
                            cursor: 'pointer',
                        }}
                            onClick={() => {this.edit(listRow[listRow.length -1])}}
                            className="listing-table-column">
                            {listItem}
                        </td>
                    );
                } else if (index < listRow.length - 1)  {
                    return (
                        <td className="listing-table-column">
                            {listItem}
                        </td>
                    );
                } else {
                    return (
                        <td className="listing-table-column">
                            <img
                                src={editIcon}
                                onClick={() => {this.edit(listItem)}}
                                alt="edit"
                            />
                            <img
                                src={deleteIcon}
                                onClick={() => {this.delete(listItem)}}
                                alt="delete"
                            />
                            <FontAwesomeIcon
                                style={{
                                    color: '#475f87',
                                }}
                                onClick={() => this.impersonate(listRow[2])}
                                size='lg'
                                icon={faPeopleArrows}
                            />
                        </td>
                    );
                }
            });

            return (
                <tr className="listing-table-rows">
                    {item}
                </tr>
            );
        });
    };

    renderPagination = () => {
        const { totalRecords, currentPage } = this.props;

        const totalPages = Math.ceil(totalRecords / RECORDS_PER_PAGE);

        const visiblePaginationPages = 6;

        const pageIndexes = [];

        const visiblePages = visiblePaginationPages / 2;
        if (totalPages > visiblePaginationPages + 2) {
            const firstPage = Math.min(currentPage, Math.max(totalPages - (visiblePages * 2), 0));
            for (let i = firstPage; i < firstPage + visiblePages; i++) {
                pageIndexes.push(i);
            }

            if (currentPage + visiblePages < totalPages - visiblePages) {
                pageIndexes.push(-1);
            }

            for (let i = totalPages - visiblePages; i < totalPages; i++) {
                pageIndexes.push(i);
            }
        } else {
            for (let i = 1; i <= totalPages; i++) {
                pageIndexes.push(i);
            }
        }

        const pageLinks = pageIndexes.map((i, index) => {
            const button = <button
                disabled={currentPage === i}
                key={`pagination-button-${index}`}
                onClick={() => { return this.props.goToPage(i); }}
                className="listing-pagination-button">
                {i}
            </button>;

            const ellipsis = <div className="listing-pagination-button" key={`pagination-ellipsis-${index}`}>...</div>;
            return (
                -1 === i ? ellipsis : button
            );
        });
        return (
            <div className="listing-pagination">
                <div className="inner">
                    <button
                        onClick={() => { return this.props.goToPage(1); }}
                        disabled={1 === currentPage}
                        className="listing-pagination-button">
                        &lt;&lt; First
                    </button>
                    <button
                        onClick={() => { return this.props.goToPage(currentPage - 1); }}
                        disabled={1 === currentPage}
                        className="listing-pagination-button">
                        &lt; Previous
                    </button>
                    {pageLinks}
                    <button
                        onClick={() => { return this.props.goToPage(currentPage + 1); }}
                        disabled={currentPage === totalPages - 1}
                        className="listing-pagination-button">
                        Next &gt;
                    </button>
                    <button
                        onClick={() => { return this.props.goToPage(totalPages - 1); }}
                        disabled={currentPage === totalPages - 1}
                        className="listing-pagination-button">
                        Last &gt;&gt;
                    </button>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="x_panel">
                <div className="x_content">
                    <div className="table-responsive">
                        <table className="listing-table">
                            <thead className="listing-table-head">
                            <tr>
                                {this.renderHeadColumn()}
                            </tr>
                            </thead>
                            {this.props.isLoading && (
                                <Loader width='168%' />
                            )}
                            {!this.props.isLoading && (
                                <tbody>
                                    {this.renderList()}
                                </tbody>
                            )}
                        </table>
                    </div>
                    {this.props.totalRecords > 10 && this.props.listing.length > 0
                        ? this.renderPagination()
                        : <div style={{
                            width: '100%',
                            textAlign: 'center',
                            paddingTop: '2px',
                          }}>
                            { !this.props.isLoading && this.props.listing.length === 0 && (
                                <span className="noRecord">No Records Found</span>
                            )}
                          </div>
                    }
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            impersonate,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(UserListingTable));