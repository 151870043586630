import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { RevenueSchedulerService } from "../../services/revenuescheduler";
import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";
import { withRouter } from "react-router-dom";
import ManageBilling from "../molecules/ManageBilling";
class BillingNotes extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    eventId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setModal: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
  };

  state = {
    patientId: "",
    billing_notes: "",
    billId: "",
    eventId: "",
    files: [],
  };

  componentDidMount() {
    console.log("this.props", this.props);
    if (this.props) {
      this.setState({
        eventId: this.props.eventId,
        billId: this.props.bill_Id,
      });
    }
  }
  render() {
    const { title, isOpen, setModal } = this.props;
    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={() => setModal(!isOpen)}
          style={{
            maxWidth: "90%",
          }}
        >
          <ModalHeader
            toggle={() => setModal(!isOpen)}
            style={{ backgroundColor: "#1b252e", color: "white" }}
          >
            {title}
          </ModalHeader>
          <ModalBody
            style={{
              backgroundColor: "#1b252e",
            }}
          >
            <React.Fragment>
              <div className="col-md-12 mrgn-btm10">
                <ManageBilling
                  eventId={this.state.eventId}
                  billId={this.state.billId}
                  PopupRequest={1}
                ></ManageBilling>
              </div>
            </React.Fragment>
          </ModalBody>
          <ModalFooter
            style={{
              backgroundColor: "#1b252e",
            }}
          >
            <Button color="secondary" onClick={() => setModal(!isOpen)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(BillingNotes);
