import React from "react";
import "../../../sass/topnavbar.scss";

import NotificationList from "../atom/NotificationList";

import profileImg from "../../../assets/images/img.jpg";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
  BASE_URL,
} from "../../constants/common";
import { bindActionCreators } from "redux";
import { authenticate } from "../../actions/authentication";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createNotification } from "../../utils/notificationManager";

import { authenticationService } from "../../services/authentication";
import { notificationService } from "../../services/notifications";

const loggedInUser = JSON.parse(
  localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
);

class TopNavbar extends React.Component {
  state = {
    isOpen: false,
    checked: false,
    theme: (loggedInUser && loggedInUser.theme) || "dark",
    notificationCount: 0,
    totalNotifications: 0,
    showNotification: false,
    defaultprofileImg: profileImg,
  };

  componentDidMount() {
    
    this.setState({
      checked: !(loggedInUser && loggedInUser.theme === "dark"),
    });

    if (loggedInUser) {
      notificationService
        .getNotificationsCount(loggedInUser.id)
        .then((response) => {
          this.setState({ notificationCount: response.records });
        });
        if(loggedInUser.image != "assets/avatar/" ){
          this.setState({
            defaultprofileImg: BASE_URL.replace("api/",'') + loggedInUser.image,
          });
        }
    }
  }

  logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  handleChange = (checked) => {
    this.setState({ checked: checked }, () => {
      this.setState({ theme: checked ? "light" : "dark" }, () => {
        const { theme } = this.state;
        const loggedInUser = JSON.parse(
          localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
        );
        if (loggedInUser) {
          const request = {
            id: loggedInUser.id,
            theme,
          };
          authenticationService.updateTheme(request).then((response) => {
            loggedInUser.theme = theme;
            localStorage.setItem(
              LOCAL_STORAGE_KEYS.LOGGED_IN_USER,
              JSON.stringify(loggedInUser)
            );
            window.location.reload();
          });
        }
      });
    });
  };

  revertImpersonate = () => {
    const { authenticate } = this.props;
    const impersonateEmail = localStorage.getItem(
      LOCAL_STORAGE_KEYS.IMPERSONATE_EMAIL
    );
    const request = { email: impersonateEmail };

    authenticate(request)
      .then((res) => {
        if (res.response.data.token) {
          this.props.history.push(APP_ROUTES.USER_DASHBOARD);
        } else {
          createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
        }
      })
      .catch((err) => {});
  };

  resetCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
  };

  loadNotifications = () => {
    if (!this.state.showNotification) {
      const options = {
        page_size: 5,
      };
      notificationService
        .getNotifications({ params: options }, loggedInUser.id)
        .then((response) => {
          this.setState(
            {
              notifications: response.data,
              totalNotifications: response.records,
            },
            () => {
              this.toggleNotification(true);
            }
          );
        });
    } else {
      this.toggleNotification(false);
    }
  };

  toggleNotification = (value) => {
    this.setState({ showNotification: value });
  };

  navigateToNotificationPage = () => {
    this.props.history.push({
      pathname: APP_ROUTES.NOTIFICATION,
      state: {
        activePage: "notifications",
      },
    });
  };

  render() {
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    const impersonateEmail = localStorage.getItem(
      LOCAL_STORAGE_KEYS.IMPERSONATE_EMAIL
    );
    const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
    const {
      notificationCount,
      showNotification,
      notifications,
      totalNotifications,
    } = this.state;
    return (
      <div
        className="col-12"
        style={{
          float: "right",
          zIndex: 1,
        }}
      >
        <div className="top-nav">
          <div
            className="nav_menu"
            style={{
              float: "right",
              background: impersonateEmail ? "#343a40" : "#EDEDED",
            }}
          >
            <div
              className="dropdown pull-right"
              onClick={this.toggleOpen}
              style={{ float: "right" }}
            >
              <a
                className="user-profile"
                data-toggle="dropdown"
                style={{ float: "right" }}
              >
                <img src={this.state.defaultprofileImg} className="user-profile-image" />
                {`${loggedInUser.name} ${loggedInUser.last_name}`}
              </a>
              <div
                className={menuClass}
                aria-labelledby="dropdownMenuButton"
                style={{ float: "right", minwidth: "0px", width: "100%" }}
              >
                <Link className="dropdown-item" to="/profile">
                  Profile
                </Link>
                {/* <a className="dropdown-item" href="/users">
                  Switch User
                </a> */}
                <a className="dropdown-item" href="#nogo" onClick={this.logout}>
                  Logout
                </a>
              </div>
            </div>
            <div
              style={{
                paddingTop: "18px",
                paddingRight: "15px",
                alignItems: "center",
                display: "flex",
              }}
              className="pull-right"
            >
              <span
                style={{
                  color: "#57b1b1",
                  paddingRight: "5px",
                  fontWeight: "500",
                }}
              >
                Dark
              </span>
              <Switch
                checked={this.state.checked}
                onChange={this.handleChange}
                onColor="#475f87"
                onHandleColor="#FFF"
                offColor="#FFF"
                offHandleColor="#475f87"
                handleDiameter={20}
                uncheckedIcon={true}
                checkedIcon={true}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
              />
              <span
                style={{
                  paddingLeft: "5px",
                  color: "#57b1b1",
                  fontWeight: "500",
                }}
              >
                Light
              </span>
            </div>
            {window.location.href.indexOf("notification") < 1 && (
              <div
                style={{
                  float: "right",
                  paddingTop: "7px",
                }}
                onClick={this.loadNotifications}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="35"
                  viewBox="0 0 512 512"
                  style={{
                    paddingTop: "10px",
                    paddingRight: "15px",
                  }}
                >
                  <path
                    fill="rgb(87, 177, 177)"
                    d="M450.201 407.453c-1.505-.977-12.832-8.912-24.174-32.917-20.829-44.082-25.201-106.18-25.201-150.511 0-.193-.004-.384-.011-.576-.227-58.589-35.31-109.095-85.514-131.756V57.036C315.301 25.586 289.757 0 258.359 0h-4.719c-31.398 0-56.942 25.586-56.942 57.036v34.655c-50.372 22.734-85.525 73.498-85.525 132.334 0 44.331-4.372 106.428-25.201 150.511-11.341 24.004-22.668 31.939-24.174 32.917-6.342 2.935-9.469 9.715-8.01 16.586 1.473 6.939 7.959 11.723 15.042 11.723h109.947C179.391 477.903 213.785 512 256 512s76.609-34.097 77.223-76.238H443.17c7.082 0 13.569-4.784 15.042-11.723 1.457-6.871-1.669-13.652-8.011-16.586zM226.699 57.036c0-14.881 12.086-26.987 26.942-26.987h4.719c14.856 0 26.942 12.106 26.942 26.987v24.917a144.658 144.658 0 00-29.306-2.987A144.695 144.695 0 00226.7 81.95V57.036zM256 481.951c-25.673 0-46.614-20.617-47.223-46.188h94.445c-.608 25.57-21.549 46.188-47.222 46.188zm60.4-76.239H103.015c2.595-4.044 5.236-8.623 7.861-13.798 20.104-39.643 30.298-96.129 30.298-167.889 0-63.417 51.509-115.01 114.821-115.01s114.821 51.593 114.821 115.06c0 .185.003.369.01.553.057 71.472 10.25 127.755 30.298 167.286 2.625 5.176 5.267 9.754 7.861 13.798z"
                  ></path>
                </svg>
                {notificationCount > 0 && (
                  <span
                    style={{
                      background: "orange",
                      width: "15px",
                      display: "block",
                      textAlign: "center",
                      height: "15px",
                      borderRadius: "50%",
                      color: "#fff",
                      fontSize: "9px",
                      lineHeight: "15px",
                      position: "absolute",
                      right: "300px",
                      bottom: "40px",
                    }}
                  >
                    {notificationCount}
                  </span>
                )}
              </div>
            )}
            <div
              style={{
                paddingTop: "15px",
                paddingRight: "15px",
                alignItems: "center",
                display: "flex",
              }}
              className="pull-right"
            >
              <button
                type="submit"
                onClick={this.resetCache}
                className="filter-footer-button"
              >
                Clear Cache
              </button>
            </div>
            {showNotification && (
              <NotificationList
                notifications={notifications || []}
                showNotification={showNotification}
                totalNotifications={totalNotifications}
                navigateToNotificationPage={this.navigateToNotificationPage}
                toggleNotification={this.toggleNotification}
              />
            )}
            {impersonateEmail && (
              <div
                style={{
                  paddingTop: "18px",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <span
                  style={{
                    paddingLeft: "22px",
                    color: "#57b1b1",
                    fontWeight: "500",
                  }}
                >
                  You are Currently Impersonated as {impersonateEmail}, Click
                  <button
                    style={{
                      background: "#343a40",
                      color: "#57b1b1",
                      border: "none",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontWeight: "500",
                    }}
                    onClick={this.revertImpersonate}
                  >
                    here
                  </button>
                  to undo Impersonation
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      authenticate,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(TopNavbar));
