import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import {API_CONSTANTS} from "../constants/api";

function getInsurances(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_INSURANCES, options)
        .then(handleResponse)
        .catch(handleError);
}

function getInsuranceDetail(insurance) {
    return apiClient()
        .get(API_CONSTANTS.INSURANCE_DETAIL + insurance)
        .then(handleResponse)
        .catch(handleError);
}

function createInsurance(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_INSURANCE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateInsurance(payload) {
    return apiClient()
        .put(API_CONSTANTS.UPDATE_INSURANCE, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteInsurance(ins) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_INSURANCE + ins)
        .then(handleResponse)
        .catch(handleError);
}

export const insuranceService = {
    getInsurances,
    getInsuranceDetail,
    createInsurance,
    updateInsurance,
    deleteInsurance
}