import React from "react";
import {bindActionCreators} from "redux";
import {createAssistant, updateAssistant} from "../../actions/assistant";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {APP_ROUTES, NOTIFICATION_TYPES, RECORDS_PER_PAGE} from "../../constants/common";
import {createNotification} from "../../utils/notificationManager";
import DatePicker from "react-datepicker";
import {AsyncPaginate} from "react-select-async-paginate";
import {regionsService} from "../../services/regions";
import {assistantService} from "../../services/assistants";
import {formatDate} from "../../utils/date";

const REQUIRED_FIELDS = {
    NAME: 'name',
    LAST_NAME: 'lastName',
    EMAIL: 'email',
    REGION: 'region',
    CERTIFICATE: 'certificate',
};

class ManageAssistant extends React.Component {

    state = {
        name: '',
        lastName: '',
        email: '',
        personalEmail: '',
        phone: '',
        personalPhone: '',
        active: '',
        role: '',
        homeAddress: '',
        homeAddress2: '',
        state: '',
        city: '',
        zip: '',
        practiceAddress: '',
        practiceAddress2: '',
        practiceState: '',
        practiceCity: '',
        practiceZip: '',
        primaryTaxonomy: '',
        secondaryTaxonomy: '',
        drivingLicense: '',
        drivingLicenseState: '',
        drivingLicenseIssueDate: '',
        drivingLicenseExpiryDate: '',
        certificate: {},
        licenseCertificate: '',
        certificateIssueDate: '',
        certificateExpiryDate: '',
        region: {},
        npi: '',
        startDate: '',
        endDate: '',
        errors: [],
    };

    componentDidMount() {
        const assistantId = this.props.location.state ? this.props.location.state.assistantId : '';
        if (assistantId) {
            assistantService
                .getAssistantDetail(assistantId)
                .then(response => {
                    this.setState({
                        name: response.data.name || '',
                        lastName: response.data.last_name || '',
                        email: response.data.assistant ? response.data.assistant.work_email : '',
                        personalEmail: response.data.assistant ? response.data.assistant.personal_email : '',
                        phone: response.data.assistant ? response.data.assistant.work_cell_phone : '',
                        personalPhone: response.data.assistant ? response.data.assistant.personal_cell_phone : '',
                        active: response.data.is_active,
                        role: response.data.type,
                        homeAddress: response.data.assistant ? response.data.assistant.practice1_address1 : '',
                        homeAddress2: response.data.assistant ? response.data.assistant.practice1_address2 : '',
                        state: response.data.assistant ? response.data.assistant.practice1_state : '',
                        city: response.data.assistant ? response.data.assistant.practice1_city : '',
                        zip: response.data.assistant ? response.data.assistant.practice1_zip : '',
                        practiceAddress: response.data.assistant ? response.data.assistant.practice2_address1 : '',
                        practiceAddress2: response.data.assistant ? response.data.assistant.practice2_address2 : '',
                        practiceState: response.data.assistant ? response.data.assistant.practice2_state : '',
                        practiceCity: response.data.assistant ? response.data.assistant.practice2_city : '',
                        practiceZip: response.data.assistant ? response.data.assistant.practice2_zip : '',
                        primaryTaxonomy: response.data.assistant ? response.data.assistant.primary_taxonomy : '',
                        secondaryTaxonomy: response.data.assistant ? response.data.assistant.secondary_taxonomy : '',
                        drivingLicense: response.data.assistant ? response.data.assistant.driving_license : '',
                        drivingLicenseState: response.data.assistant ? response.data.assistant.driving_license_state : '',
                        drivingLicenseIssueDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.driving_issue_date)) : '',
                        drivingLicenseExpiryDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.driving_expiry_date)) : '',
                        certificate: {
                            label: response.data.assistant && response.data.assistant.certificate ? response.data.assistant.certificate.name : '',
                            value: response.data.assistant && response.data.assistant.certificate ? parseInt(response.data.assistant.certificate.id) : '',
                        },
                        licenseCertificate: response.data.assistant ? response.data.assistant.certificate_number : '',
                        certificateIssueDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.license_issue_date)) : '',
                        certificateExpiryDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.license_expiry_date)) : '',
                        region: {
                            label: response.data.assistant && response.data.assistant.region ? response.data.assistant.region.name : '',
                            value: response.data.assistant && response.data.assistant.region ? parseInt(response.data.assistant.region.company_id) : '',
                        },
                        npi: response.data.assistant ? response.data.assistant.npi : '',
                        startDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.joining_date)) : '',
                        endDate: response.data.assistant ? Date.parse(formatDate(response.data.assistant.termination_date)) : '',
                    });
                });
        }
    }

    hasErrors = (field) => {
        return this.state.errors.includes(field);
    };

    resetErrors = (field) => {
        this.setState({
            errors: this.state.errors.filter(fieldName => fieldName !== field)
        });
    }

    validateAssistant = () => {
        const {
            email,
            name,
            lastName,
            errors,
        } = this.state;

        let isValid = true;

        if (name.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.NAME] });
            isValid = false;
        } else if (lastName.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.LAST_NAME] });
            isValid = false;
        } else if (email.length === 0) {
            this.setState({ errors: [...errors, REQUIRED_FIELDS.EMAIL] });
            isValid = false;
        }

        if (!isValid) {
            createNotification(NOTIFICATION_TYPES.ERROR, 'Please select required values');
        }

        return isValid;
    };

    submitAssistant = () => {
        const { createAssistant, updateAssistant } = this.props;
        const assistantId = this.props.location.state ? this.props.location.state.assistantId : '';
        if (!this.validateAssistant()) {
            return;
        }

        const {
            name,
            lastName,
            email,
            personalEmail,
            phone,
            personalPhone,
            active,
            role,
            homeAddress,
            homeAddress2,
            city,
            state,
            zip,
            practiceAddress,
            practiceAddress2,
            practiceState,
            practiceCity,
            practiceZip,
            primaryTaxonomy,
            secondaryTaxonomy,
            drivingLicense,
            drivingLicenseState,
            drivingLicenseIssueDate,
            drivingLicenseExpiryDate,
            certificate,
            licenseCertificate,
            certificateIssueDate,
            certificateExpiryDate,
            region,
            npi,
            startDate,
            endDate,
        } = this.state;

        const request = {
            first_name: name,
            last_name: lastName,
            email,
            work_email: email,
            personal_email: personalEmail,
            password: '123456',
            work_cell_phone: phone,
            personal_cell_phone: personalPhone,
            is_active: active,
            type: role,
            practice1_address1: homeAddress,
            practice1_address2: homeAddress2,
            practice1_state: state,
            practice1_city: city,
            practice1_zip: zip,
            practice2_address1: practiceAddress,
            practice2_address2: practiceAddress2,
            practice2_state: practiceState,
            practice2_city: practiceCity,
            practice2_zip: practiceZip,
            driving_license: drivingLicense,
            driving_license_state: drivingLicenseState,
            driving_issue_date: formatDate(drivingLicenseIssueDate),
            driving_expiry_date: formatDate(drivingLicenseExpiryDate),
            npi,
            primary_taxonomy: primaryTaxonomy,
            secondary_taxonomy: secondaryTaxonomy,
            certificate: certificate.value,
            certificate_number: licenseCertificate,
            region: region.value,
            license_issue_date: formatDate(certificateIssueDate),
            license_expiry_date: formatDate(certificateExpiryDate),
            joining_date: formatDate(startDate),
            termination_date: formatDate(endDate),
        };

        if (assistantId) {
            updateAssistant({...request, 'id': assistantId})
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push({
                            pathname: APP_ROUTES.SURGICAL_ASSISTANT_DASHBOARD,
                            state: {
                                activePage: 'surgical_assistants',
                                regionId: this.props.regionId || this.props.location.state.regionId,
                            }
                        });
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update assistant ${JSON.stringify(res.response.data)}`);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to update assistant ${err.error.response.data.message}`);
                });
        } else {
            createAssistant(request)
                .then(res => {
                    if (res.response.success) {
                        createNotification(NOTIFICATION_TYPES.SUCCESS, res.response.message);
                        this.props.history.push({
                            pathname: APP_ROUTES.SURGICAL_ASSISTANT_DASHBOARD,
                            state: {
                                activePage: 'surgical_assistants',
                                regionId: this.props.regionId || this.props.location.state.regionId,
                            }
                        });
                    } else {
                        createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create assistant ${JSON.stringify(res.response.data)}`);
                    }
                })
                .catch(err => {
                    createNotification(NOTIFICATION_TYPES.ERROR, `Unable to create assistant ${err.error.response.data.message}`);
                });
        }
    };

    formatPhone = (str) => {
        if (str) {
            let phone = '';
            str = str.replace(/\D/g, '').substring(0,10);
            if (str.length < 3) {
                phone += str;
            } else if (str.length < 6) {
                phone += str.substring(0, 3) + '-';
                if (str.length > 3) {
                    phone += str.substring(3, str.length);
                }
            } else {
                phone += str.substring(0, 3) + '-' + str.substring(3, 6) + '-' + str.substring(6, 10);
            }
            return phone;
        }
        return str;
    };

    loadRegion = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return regionsService
            .getRegions({ params: options })
            .then(response => {
                const options = response.data.map(region => (
                    {
                        value: region.id,
                        label: region.name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };

    loadCertificates = (search, prevOptions) => {
        let options;

        if (search) {
            options = {
                search,
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        } else {
            options = {
                page: (prevOptions.length / RECORDS_PER_PAGE) + 1,
            };
        }

        return assistantService
            .getCertificates({ params: options })
            .then(response => {
                const options = response.data.map(certificate => (
                    {
                        value: certificate.id,
                        label: certificate.name,
                    }
                ));

                return {
                    options: options,
                    hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
                }
            });
    };


    render() {
        const activePage = this.props.location.state ? this.props.location.state.activePage : '';
        const assistantId = this.props.location.state ? this.props.location.state.assistantId : '';
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col"
                     style={{
                         minHeight: '125vh',
                     }}>
                    <Sidebar activePage={activePage}/>
                </div>
                <TopNavbar />
                <div className="manage-activity-right-col">
                    <div className="activity-row">
                        <div className="page-title">
                            {assistantId &&
                            <h2 className="heading-custom">Edit Assistant</h2>
                            }
                            {!assistantId &&
                            <h2 className="heading-custom">Add Assistant</h2>
                            }
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>First Name <span className="required">*</span></label>
                            <input
                                className={this.hasErrors(REQUIRED_FIELDS.NAME) ? 'error code-input' : 'code-input'}
                                type="text"
                                value={this.state.name}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.NAME);
                                    this.setState({ name: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Last Name <span className="required">*</span></label>
                            <input
                                className={this.hasErrors(REQUIRED_FIELDS.LAST_NAME) ? 'error code-input' : 'code-input'}
                                type="text"
                                value={this.state.lastName}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.LAST_NAME);
                                    this.setState({ lastName: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Work Email <span className="required">*</span></label>
                            <input
                                className={this.hasErrors(REQUIRED_FIELDS.EMAIL) ? 'error code-input' : 'code-input'}
                                type="text"
                                value={this.state.email}
                                onChange={event => {
                                    this.resetErrors(REQUIRED_FIELDS.EMAIL);
                                    this.setState({ email: event.target.value })
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Personal Email</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.personalEmail}
                                onChange={event => this.setState({ personalEmail: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Work Cell Phone</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.formatPhone(this.state.phone)}
                                onChange={event => this.setState({ phone: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Personal Cell Phone</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.formatPhone(this.state.personalPhone)}
                                onChange={event => this.setState({ personalPhone: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Active</label>
                            <select
                                value={this.state.active}
                                className="filter-input"
                                onChange={event => this.setState({ active: event.target.value })}>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Role</label>
                            <select
                                value={this.state.role}
                                className="filter-input"
                                onChange={event => this.setState({ role: event.target.value })}>
                                <option value="">Please Select Role</option>
                                <option value="assistant">Assistant</option>
                                <option value="manager">Manager</option>
                            </select>
                        </div>
                        <div className="page-title">
                            <h2 className="heading-custom">Home Address</h2>
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Home Address</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.homeAddress}
                                onChange={event => this.setState({ homeAddress: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Home Address 2</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.homeAddress2}
                                onChange={event => this.setState({ homeAddress2: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>State</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.state}
                                onChange={event => this.setState({ state: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>City</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.city}
                                onChange={event => this.setState({ city: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Zip</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.zip}
                                onChange={event => this.setState({ zip: event.target.value })}
                            />
                        </div>
                        <div className="page-title">
                            <h2 className="heading-custom">Practice Address</h2>
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Practice Address</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.practiceAddress}
                                onChange={event => this.setState({ practiceAddress: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Practice Address 2</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.practiceAddress2}
                                onChange={event => this.setState({ practiceAddress2: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>State</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.practiceState}
                                onChange={event => this.setState({ practiceState: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>City</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.practiceCity}
                                onChange={event => this.setState({ practiceCity: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Zip</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.practiceZip}
                                onChange={event => this.setState({ practiceZip: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Primary Taxonomy</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.primaryTaxonomy}
                                onChange={event => this.setState({ primaryTaxonomy: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Secondary Taxonomy</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.secondaryTaxonomy}
                                onChange={event => this.setState({ secondaryTaxonomy: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Driving License</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.drivingLicense}
                                onChange={event => this.setState({ drivingLicense: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Driving License State</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.drivingLicenseState}
                                onChange={event => this.setState({ drivingLicenseState: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Driving License Issue Date</label>
                            <br/>
                            <DatePicker
                                isClearable
                                selected={this.state.drivingLicenseIssueDate}
                                onChange={date => this.setState({drivingLicenseIssueDate: date})}
                                className="date-picker"
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Driving License Expiry Date</label>
                            <br/>
                            <DatePicker
                                isClearable
                                selected={this.state.drivingLicenseExpiryDate}
                                onChange={date => this.setState({drivingLicenseExpiryDate: date})}
                                className="date-picker"
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Choose a certificate <span className="required">*</span></label>
                            <AsyncPaginate
                                className={this.hasErrors(REQUIRED_FIELDS.CERTIFICATE) ? 'error' : ''}
                                placeholder="-- Certificate --"
                                value={this.state.certificate}
                                loadOptions={this.loadCertificates}
                                defaultOptions={[
                                    {
                                        value: '',
                                        label: 'Select Certificate',
                                    }
                                ]}
                                onChange={value => {
                                    this.resetErrors(REQUIRED_FIELDS.CERTIFICATE);
                                    this.setState({ certificate: value });
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>License Certificate #</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.licenseCertificate}
                                onChange={event => this.setState({ licenseCertificate: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Certificate Issue Date</label>
                            <br/>
                            <DatePicker
                                isClearable
                                selected={this.state.certificateIssueDate}
                                onChange={date => this.setState({certificateIssueDate: date})}
                                className="date-picker"
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Certificate Expiry Date</label>
                            <br/>
                            <DatePicker
                                isClearable
                                selected={this.state.certificateExpiryDate}
                                onChange={date => this.setState({certificateExpiryDate: date})}
                                className="date-picker"
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Region <span className="required">*</span></label>
                            <AsyncPaginate
                                className={this.hasErrors(REQUIRED_FIELDS.REGION) ? 'error' : ''}
                                placeholder="-- Region --"
                                value={this.state.region}
                                loadOptions={this.loadRegion}
                                defaultOptions={[
                                    {
                                        value: '',
                                        label: 'Select Region',
                                    }
                                ]}
                                onChange={value => {
                                    this.resetErrors(REQUIRED_FIELDS.REGION);
                                    this.setState({ region: value });
                                }}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>NPI #</label>
                            <input
                                className='code-input'
                                type="text"
                                value={this.state.npi}
                                onChange={event => this.setState({ npi: event.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Start Date</label>
                            <br/>
                            <DatePicker
                                isClearable
                                selected={this.state.startDate}
                                onChange={date => this.setState({startDate: date})}
                                className="date-picker"
                            />
                        </div>
                        <div className="col-md-6 mrgn-btm10">
                            <label>Termination Date</label>
                            <br/>
                            <DatePicker
                                isClearable
                                selected={this.state.endDate}
                                onChange={date => this.setState({endDate: date})}
                                className="date-picker"
                            />
                        </div>
                        <div className="filter-footer">
                            <button
                                onClick={this.submitAssistant}
                                className="filter-save-button">
                                Submit
                            </button>
                            <button
                                onClick={() => { this.props.history.push({
                                    pathname: APP_ROUTES.SURGICAL_ASSISTANT_DASHBOARD,
                                    state: {
                                        activePage: 'surgical_assistants',
                                        regionId: this.props.regionId || this.props.location.state.regionId,
                                    }
                                })}}
                                className="filter-cancel-button">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            createAssistant,
            updateAssistant,
        },
        dispatch,
    );
}

export default connect(
    null,
    mapDispatchToProps,
)(withRouter(ManageAssistant));