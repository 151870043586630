import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import "../../../sass/activityfilters.scss";
import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import DatePicker from "react-datepicker";
import { AsyncPaginate } from "react-select-async-paginate";
import { regionsService } from "../../services/regions";
import { assistantService } from "../../services/assistants";
import { surgeonService } from "../../services/surgeons";
import { facilityService } from "../../services/facilities";
import { patientService } from "../../services/patients";
import axios from "axios";
import { userService } from "../../services/users";

class InvoicingFilters extends React.Component {
  static propTypes = {
    setFromDate: PropTypes.func.isRequired,
    setToDate: PropTypes.func.isRequired,
    setRegion: PropTypes.func.isRequired,
    setAssistant: PropTypes.func.isRequired,
    setSurgeon: PropTypes.func.isRequired,
    setSearchInvoice: PropTypes.func.isRequired,
    setFacility: PropTypes.func.isRequired,
    setPatient: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    setCreatedBy: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.cancel = "";
  }

  showAdd = () => this.props.history.push(APP_ROUTES.ADD_INVOICE);

  loadRegion = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return regionsService
      .getRegions({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((region) => ({
          value: region.id,
          label: region.name,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadAssistant = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    if (this.props.region.value) {
      return assistantService
        .getRegionAssistants(this.props.region.value)
        .then((response) => {
          const options = response.data.map((assistant) => ({
            value: assistant.assistant_id,
            label: `${assistant.name} ${assistant.last_name}`,
          }));

          return {
            options: options,
            hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
          };
        });
    } else {
      return assistantService
        .getAssistants({ params: options }, this.cancel.token)
        .then((response) => {
          const options = response.data.map((assistant) => ({
            value: assistant.assistant_id,
            label: `${assistant.first_name} ${assistant.last_name}`,
          }));

          return {
            options: options,
            hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
          };
        });
    }
  };

  loadSurgeon = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return surgeonService
      .getSurgeons({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((surgeon) => ({
          value: surgeon.id,
          label: `${surgeon.first_name} ${surgeon.last_name}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadFacility = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return facilityService
      .getFacilities({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((facility) => ({
          value: facility.id,
          label: facility.facility_name,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadPatient = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return patientService
      .getPatients({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((patient) => ({
          value: patient.id,
          label: `${patient.first_name} ${patient.last_name}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadStatus = () => {
    const options = [
      {
        value: "NOT STARTED",
        label: "NOT STARTED",
      },
      {
        value: "SUBMITTED",
        label: "SUBMITTED",
      },
      {
        value: "CORRECTED CLAIM",
        label: "CORRECTED CLAIM",
      },
      {
        value: "PAPER CLAIM",
        label: "PAPER CLAIM",
      },
      {
        value: "RECONSIDERATION",
        label: "RECONSIDERATION",
      },
      {
        value: "FOLLOW-UP",
        label: "FOLLOW-UP",
      },
      {
        value: "APPEAL 1",
        label: "APPEAL 1",
      },
      {
        value: "APPEAL 2",
        label: "APPEAL 2",
      },
      {
        value: "HOLD TO BILL",
        label: "HOLD TO BILL",
      },
      {
        value: "BILL PATIENT",
        label: "BILL PATIENT",
      },
      {
        value: "BILL HOSPITAL",
        label: "BILL HOSPITAL",
      },
      {
        value: "BILL PHYSICIAN",
        label: "BILL PHYSICIAN",
      },
      {
        value: "REVIEW ADJUSTMENT",
        label: "REVIEW ADJUSTMENT",
      },
      {
        value: "CLOSED",
        label: "CLOSED",
      },
      {
        value: "CLOSED NON-BILL",
        label: "CLOSED NON-BILL",
      },
      {
        value: "CLOSED ADJUSTMENT",
        label: "CLOSED ADJUSTMENT",
      },
      {
        value: "CLOSED HC-2nd Scrub",
        label: "CLOSED HC-2nd Scrub",
      },

    ];

    return {
      options: options,
      hasMore: false,
    };
  };


  loadUsers = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return userService
      .getUsers({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((user) => ({
          value: user.id,
          label: `${user.name} ${user.last_name}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  resetFilter = () => {
    
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    this.props.setFromDate("");
    this.props.setToDate("");
    if (loggedInUser.type !== "company") {
      this.props.setRegion({
        value: "",
        label: "Select Region",
      });
    }
    if (loggedInUser.type !== "assistant") {
      this.props.setAssistant({
        value: "",
        label: "Select Assistant",
      });
    }
    this.props.setSurgeon({
      value: "",
      label: "Select Surgeon",
    });
    this.props.setSearchInvoice({
      value: "",
    });
    this.props.setFacility({
      value: "",
      label: "Select Facility",
    });
    this.props.setPatient({
      value: "",
      label: "Select Patient",
    });
    this.props.setCreatedBy({
      value: "",
      label: "Select Created By",
    });
    this.props.setStatus(
      {
        value: "",
        label: "Select Status",
      },
      -1
    );
  };

  render() {
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    return (
      <>
        <div className="page-title">
          <div className="title_left">
            <h3
              style={{
                fontFamily:
                  "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                fontSize: "24px",
                fontWeight: "400",
                lineHeight: "1.471",
              }}
            >
              Invoicing List
            </h3>
          </div>
        </div>
        {/* {loggedInUser.isAddAccess && (
          <div className="filter-footer-header">
            <button
              onClick={() => {
                this.showAdd(true);
              }}
              className="filter-button"
            >
              Add New Invoice
            </button>
          </div>
        )} */}
        <div className="custom-class">
          <div className="filters-column">
            <label>From Date</label>
            <DatePicker
              isClearable
              className="filter-input"
              selected={this.props.fromDate}
              onChange={(date) => this.props.setFromDate(date)}
            />
          </div>
          <div className="filters-column">
            <label>To Date</label>
            <DatePicker
              isClearable
              className="filter-input"
              selected={this.props.toDate}
              onChange={(date) => this.props.setToDate(date)}
            />
          </div>
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <label>Region</label>
              <AsyncPaginate
                placeholder="-- Region --"
                value={this.props.region}
                loadOptions={this.loadRegion}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Region",
                  },
                ]}
                onChange={(value) => this.props.setRegion(value)}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && !this.props.flipAssistant && (
            <div className="filters-column">
              <label>Assistant</label>
              <AsyncPaginate
                placeholder="-- Assistant --"
                value={this.props.assistant}
                loadOptions={this.loadAssistant}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Assistant",
                  },
                ]}
                onChange={(value) => this.props.setAssistant(value)}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && this.props.flipAssistant && (
            <div className="filters-column">
              <label>Assistant</label>
              <AsyncPaginate
                placeholder="-- Assistant --"
                value={this.props.assistant}
                loadOptions={this.loadAssistant}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Assistant",
                  },
                ]}
                onChange={(value) => this.props.setAssistant(value)}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <label>Surgeon</label>
              <AsyncPaginate
                placeholder="-- Surgeon --"
                value={this.props.surgeon}
                loadOptions={this.loadSurgeon}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Surgeon",
                  },
                ]}
                onChange={(value) => this.props.setSurgeon(value)}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <label>Facility</label>
              <AsyncPaginate
                placeholder="-- Facility --"
                value={this.props.facility}
                loadOptions={this.loadFacility}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Facility",
                  },
                ]}
                onChange={(value) => this.props.setFacility(value)}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <label>Patient</label>
              <AsyncPaginate
                placeholder="-- Patient --"
                value={this.props.patient}
                loadOptions={this.loadPatient}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Patient",
                  },
                ]}
                onChange={(value) => this.props.setPatient(value)}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <label>Created By</label>
              <AsyncPaginate
                placeholder="-- Created By --"
                value={this.props.createdBy}
                loadOptions={this.loadUsers}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Created By",
                  },
                ]}
                onChange={(value) => this.props.setCreatedBy(value)}
              />
            </div>
          )}

          {loggedInUser.type !== "assistant" && (
            <div className="filters-column">
              <label>Status</label>
              <AsyncPaginate
                placeholder="-- Status --"
                value={this.props.status}
                loadOptions={this.loadStatus}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Status",
                  },
                ]}
                onChange={(value) => this.props.setStatus(value)}
              />
            </div>
          )}
          <div className="filters-column">
            <label>Invoice Number</label>
            <input
              type="text"
              className="code-input"
              onChange={(event) => {
                this.props.setSearchInvoice(event.target.value );
              }}
            />
          </div>
          <div className="filter-footer">
            <button
              type="submit"
              onClick={this.resetFilter}
              className="filter-footer-button"
            >
              Refresh
            </button>
            <button
              type="submit"
              onClick={this.props.search}
              className="filter-footer-button"
            >
              Search
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(InvoicingFilters);
