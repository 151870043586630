import { CASElOG } from '../constants/actions';

const initialState = {
    caseLogs: [],
    totalCaseLogs: 0,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case CASElOG.FETCH_CASElOG_SUCCESS:
            return {
                ...state,
                caseLogs: action.data.data,
                totalCaseLogs: action.data.records,
            };
        case CASElOG.CREATE_CASElOG_SUCCESS:
            const createUser = [ ...state.users, action.data.data ];
            return {
                ...state,
                caseLogs: createUser,
            };
        case CASElOG.UPDATE_CASElOG_SUCCESS:
            const updateUser = [ ...state.users, action.data.data ];
            return {
                ...state,
                caseLogs: updateUser,
            }
        default:
            return state;
    }
}