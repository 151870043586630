import {FACILITY, SURGEON} from '../constants/actions';
import { facilityService } from '../services/facilities';

export const fetchFacilities = options => dispatch => {
    return new Promise((resolve, reject) => {
        facilityService
            .getFacilities(options)
            .then(response => {
                dispatch({
                    type: FACILITY.FETCH_FACILITY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const createFacility = payload => dispatch => {
    return new Promise((resolve, reject) => {
        facilityService
            .createFacility(payload)
            .then(response => {
                dispatch({
                    type: FACILITY.CREATE_FACILITY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};

export const updateFacility = payload => dispatch => {
    return new Promise((resolve, reject) => {
        facilityService
            .updateFacility(payload)
            .then(response => {
                dispatch({
                    type: FACILITY.UPDATE_FACILITY_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};