import React from "react";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { createProcedure, updateProcedure } from "../../actions/procedure";
import { procedureService } from "../../services/procedures";
import { AsyncPaginate } from "react-select-async-paginate";
import { specialityService } from "../../services/speciality";
import { facilityService } from "../../services/facilities";
const REQUIRED_FIELDS = {
  NAME: "NAME",
  cptcode: "cptcode",
  FACILITY: "facility",
  price: "price",
};

class ManageProcedure extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      name: "",
      cptcode: "",
      price: "",
      speciality: "",
      facility: {},
    };
    this.cancel = "";
  }
  componentDidMount() {
    const procedureId = this.props.location.state
      ? this.props.location.state.procedureId
      : "";
    if (procedureId) {
      procedureService.getProcedureDetail(procedureId).then((response) => {
        this.setState({
          name: response.data.procedure_name,
          cptcode: response.data.cptcode,
          price: response.data.price,
          speciality: response.data.procedure_id,
          facility: {
            label: response.data.facility
              ? response.data.facility.facility_name
              : "",
            value: response.data.facility
              ? parseInt(response.data.facility.id)
              : "",
          },
        });
      });
    }
  }

  hasErrors = (field) => {
    return this.state.errors.includes(field);
  };

  resetErrors = (field) => {
    this.setState({
      errors: this.state.errors.filter((fieldName) => fieldName !== field),
    });
  };

  validateProcedure = () => {
    const { name, speciality, errors } = this.state;

    let isValid = true;

    if (name.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.NAME] });
      isValid = false;
    }

    if (!isValid) {
      createNotification(
        NOTIFICATION_TYPES.ERROR,
        "Please select required values"
      );
    }

    return isValid;
  };

  submitProcedure = () => {
    if (!this.validateProcedure()) {
      return;
    }
    const { createProcedure, updateProcedure } = this.props;
    const { name, speciality, facility, cptcode, price } = this.state;

    const request = {
      procedure_name: name,
      cptcode: cptcode,
      price: price,
      specialty_id: this.state.speciality.value,
      facility_id: this.state.facility.value,
    };
    const procedureId = this.props.location.state
      ? this.props.location.state.procedureId
      : "";
    if (procedureId) {
      const payload = { ...request, id: procedureId };
      updateProcedure(payload)
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.PROCEDURE_DASHBOARD);
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
          }
        })
        .catch((err) => {
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to update Procedure ${err.error.response.data.message}`
          );
        });
    } else {
      createProcedure(request)
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            this.props.history.push(APP_ROUTES.PROCEDURE_DASHBOARD);
          } else {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            //this.props.history.push(APP_ROUTES.FACILITIES_DASHBOARD);
          }
        })
        .catch((err) => {
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to create Procedure ${err.error.response.data.message}`
          );
        });
    }
  };
  loadFacility = (search, prevOptions) => {
    let options;
    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return facilityService
      .getFacilities({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((facility) => ({
          value: facility.id,
          label: facility.facility_name,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };
  onChangeFacility = (value) => {
    this.resetErrors(REQUIRED_FIELDS.FACILITY);
    this.setState({ facility: value });
  };
  toggleCheckbox = (name, event) => {
    let obj = {};
    obj[name] = !this.state[name];
    this.setState(obj);
  };

  cancel = () => {
    alert("eee");
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    if (screen) {
      this.props.history.push(screen);
    } else {
      this.props.history.push(APP_ROUTES.PROCEDURE_DASHBOARD);
    }
  };

  loadSpeciality = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    return specialityService
      .getSpecialities({ params: options })
      .then((response) => {
        const options = response.data.map((speciality) => ({
          value: speciality.id,
          label: speciality.name,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  render() {
    const procedureId = this.props.location.state
      ? this.props.location.state.procedureId
      : "";
      const activePage = "procedures";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={activePage}/>
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {procedureId && (
                <h2 className="heading-custom">Edit Procedure</h2>
              )}
              {!procedureId && (
                <h2 className="heading-custom">Add Procedure</h2>
              )}
            </div>
            <div className="col-md-6 mrgn-btm10">
              <label>
                Name<span className="required">*</span>
              </label>
              <input
                type="text"
                className={
                  this.hasErrors(REQUIRED_FIELDS.NAME)
                    ? "error code-input"
                    : "code-input"
                }
                value={this.state.name}
                onChange={(event) => {
                  this.resetErrors(REQUIRED_FIELDS.NAME);
                  this.setState({ name: event.target.value });
                }}
              />
            </div>

            <div className="col-md-6 mrgn-btm10">
              <label>Speciality </label>
              <AsyncPaginate
                placeholder="-- Select Speciality --"
                value={this.state.speciality}
                loadOptions={this.loadSpeciality}
                onChange={(value) => this.setState({ speciality: value })}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>
                Facility <span className="required">*</span>
              </label>
              <AsyncPaginate
                placeholder="Select Facility..."
                value={this.state.facility}
                loadOptions={this.loadFacility}
                onChange={this.onChangeFacility}
                className={
                  this.hasErrors(REQUIRED_FIELDS.FACILITY) ? "error" : ""
                }
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>
                CPT Code<span className="required">*</span>
              </label>
              <input
                type="text"
                className={
                  this.hasErrors(REQUIRED_FIELDS.cptcode)
                    ? "error code-input"
                    : "code-input"
                }
                value={this.state.cptcode}
                onChange={(event) => {
                  this.resetErrors(REQUIRED_FIELDS.cptcode);
                  this.setState({ cptcode: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>
                Procedure Price<span className="required">*</span>
              </label>
              <input
                type="text"
                className={
                  this.hasErrors(REQUIRED_FIELDS.price)
                    ? "error code-input"
                    : "code-input"
                }
                value={this.state.price}
                onChange={(event) => {
                  this.resetErrors(REQUIRED_FIELDS.price);
                  this.setState({ price: event.target.value });
                }}
              />
            </div>
          </div>
          <div className="filter-footer">
            {procedureId && (
              <button
                onClick={this.submitProcedure}
                className="filter-save-button"
              >
                Update
              </button>
            )}
            {!procedureId && (
              <button
                onClick={this.submitProcedure}
                className="filter-save-button"
              >
                Submit
              </button>
            )}
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.PROCEDURE_DASHBOARD);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createProcedure,
      updateProcedure,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageProcedure));
