import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getSurgeons(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_SURGEONS, options)
        .then(handleResponse)
        .catch(handleError);
}

function createSurgeon(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_SURGEON, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateSurgeon(payload) {
    return apiClient()
        .post(API_CONSTANTS.UPDATE_SURGEON, payload)
        .then(handleResponse)
        .catch(handleError);
}

function deleteSurgeon(patient) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_SURGEON + patient)
        .then(handleResponse)
        .catch(handleError);
}

function getSurgeonDetail(surgeon) {
    return apiClient()
        .get(API_CONSTANTS.SURGEON_DETAIL + surgeon)
        .then(handleResponse)
        .catch(handleError);
}

export const surgeonService = {
    getSurgeons,
    createSurgeon,
    updateSurgeon,
    deleteSurgeon,
    getSurgeonDetail,
}